import React from "react";
import blankPhoto from "../assets/blankgroupphoto.avif";
import { Link } from "react-router-dom";

export default function GroupChatCard({ groupChat }) {
  const messages = groupChat.messages || [];

  const lastMessageSent = messages[messages.length - 1] || {};

  return (
    <div>
      <Link to={`/group-chats/${groupChat.id}`}>
        <div className="hover:bg-gray-200 cursor-pointer border border-green-400 gap-4 p-2 rounded-md flex items-center justify-start">
          <img
            className="w-12 h-12 rounded-full object-cover"
            src={groupChat.image || blankPhoto}
            alt=""
          />
          <div className="flex flex-col items-start justify-normal gap-2">
            <div className="text-blue-900 font-bold">{groupChat.name}</div>
            <div
              className={`line-clamp-1 ${
                lastMessageSent?.readStatus === "unread" ? "font-bold" : ""
              }`}
            >
              {lastMessageSent.message}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
