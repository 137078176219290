import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import TenantOccupantForm from "./TenantOccupantForm";
import Modal from "../Components/Modal";
import TenantOccupantCard from "./TenantOccupantCard";
import PageModal from "../Components/PageModal";
import TenantOccupantDetails from "./TenantOccupantDetails";
import { getDocDataSnap } from "../../../utils/firebase/Firebase.utils";

export default function OccupantsInformationFullPage({ tenant }) {
  const { id } = useParams();
  const [addMode, setAddMode] = React.useState(false);
  const [isProcesssing, setIsProcessing] = React.useState(false);
  const [occupants, setOccupants] = React.useState([]);
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedOccupant, setSelectedOccupant] = React.useState({});
  const [occupantIndex, setOccupantIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setViewMode(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    const fetchOccupants = async () => {
      getDocDataSnap("tenants", id, (tenant) => {
        setOccupants(tenant.occupantsName || []);
        setIsLoading(false);
      });
    };
    fetchOccupants();
  }, []);

  const handleAddOccupant = () => {
    setAddMode(!addMode);
  };

  const handleViewOccupant = (occupant) => {
    setViewMode(!viewMode);
    setSelectedOccupant(occupant);
    setOccupantIndex(occupants.indexOf(occupant));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="text-gray-600 text-left text-xs">
      <div className="font-bold mt-4">Occupants Information</div>

      <button className="blue-button mt-4" onClick={handleAddOccupant}>
        Add an Occupant
      </button>
      <div className=" mt-4 max-w-[1440px]">
        <div className="space-y-2">
          {occupants.map((occupant, index) => (
            <div key={index} onClick={() => handleViewOccupant(occupant)}>
              <TenantOccupantCard occupant={occupant} key={index} />
            </div>
          ))}
        </div>
      </div>

      {addMode && (
        <Modal>
          <TenantOccupantForm
            handleAddMode={handleAddOccupant}
            tenant={tenant}
          />
        </Modal>
      )}

      {viewMode && (
        <PageModal>
          <TenantOccupantDetails
            tenantId={id}
            occupantId={selectedOccupant.id}
            handleViewMode={handleViewOccupant}
            tenant={tenant}
          />
        </PageModal>
      )}
    </div>
  );
}
