import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import {
  queryAllDocsByFieldSnap,
  getDocDataSnap,
} from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import BillingCard from "./Billing/BillingCard";
import { Link } from "react-router-dom";

function Bills() {
  const currentUser = useSelector((state) => state.userObject);

  const [billings, setBillings] = React.useState([]);
  const [identifiers, setIdentifiers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const getIdentifiers = async () => {
      const data = await getDocDataSnap("users", currentUser.uid, (data) => {
        setIdentifiers(data.linkedIdentifiers);
      });
    };
    getIdentifiers();
  }, []);

  useEffect(() => {
    const getBillings = async () => {
      const allBillings = [];
      for (const identifier of identifiers) {
        const data = await queryAllDocsByFieldSnap(
          "billings",
          "identifier",
          "==",
          identifier,
          (data) => {
            allBillings.push(...data);
            setBillings(allBillings); // Set billings to the combined array
            setIsLoading(false); //
          }
        );
        // Spread the data into the allBillings array
      }
    };

    if (identifiers.length > 0) {
      getBillings();
    }
  }, [identifiers]);

  return (
    <UserScreenWindow label="Bills">
      <div>
        <div className="font-bold text-blue-900 text-center text-sm mt-8">
          Billing Details
        </div>
        <div>
          {billings.map((billing, index) => (
            <Link to={`/billing-details/${billing.id}`} key={index}>
              <BillingCard billing={billing} />
            </Link>
          ))}
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default Bills;
