import React, { useEffect, useState } from "react";
import InputBox from "../Components/InputBox";
import SelectInput from "../Components/SelectInput";

import { addNewDoc } from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function AddItemForm({ setAddMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = useState({});

  const [isProcessing, setIsProcessing] = useState(false);

  const itemTypes = ["Equipment", "Vehicle", "Facility"];
  const itemTypesOptions = itemTypes.map((item) => {
    return {
      value: item,
      label: item,
    };
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectType = (selected) => {
    setFormData({
      type: selected.value,
    });
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.uid,
      createdName: currentUser.displayName,
      createdEmail: currentUser.email,
    };

    await addNewDoc("maintenanceItems", docObject);
    toast.success("Item Successfully Added");
    setIsProcessing(false);
    setAddMode(false);
    setFormData("");
  };

  console.log(formData);
  return (
    <div className="small-form relative">
      <div className="header pt-8">Add a Maintenance Item</div>
      <button className="close-button" onClick={() => setAddMode(false)}>
        Close
      </button>
      <form action="" className="space-y-2" onSubmit={handleAddItem}>
        <SelectInput
          name="type"
          placeholder="Select Type of Item"
          options={itemTypesOptions}
          onChange={(selected) => handleSelectType(selected)}
          required
        />
        <InputBox
          name="name"
          label="Item Name"
          type="text"
          placeholder="Enter item name"
          onChange={handleChange}
          value={formData.name}
          required
        />

        <InputBox
          name="location"
          label="Location"
          type="text"
          placeholder="Enter location of equipment/facility"
          onChange={handleChange}
          value={formData.location}
          required
        />

        <InputBox
          name="description"
          label="description"
          type="text"
          placeholder="Enter Description"
          onChange={handleChange}
          value={formData.description}
          required
        />

        <div>
          <button
            type="submit"
            className="blue-button w-full disabled:bg-opacity-50"
            onClick={handleAddItem}
            disabled={setIsProcessing}
          >
            {isProcessing ? "Adding.." : "Add"}
          </button>
        </div>
      </form>
    </div>
  );
}
