import React from "react";
import { FaSwimmer } from "react-icons/fa";
import { FiFileText } from "react-icons/fi";
import { BsFillPeopleFill } from "react-icons/bs";
import { GrUserWorker } from "react-icons/gr";
import { MdOutlinePayments } from "react-icons/md";
import { MdOutlineSos } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { MdOutlineConstruction } from "react-icons/md";
import { TbBuildingCottage } from "react-icons/tb";
import { CiDeliveryTruck } from "react-icons/ci";

function RequestsSection() {
  const navigate = useNavigate();

  const requestTypes = [
    {
      label: "Bills",
      icon: <MdOutlinePayments />,
      link: "/bills",
    },
    {
      label: "Dues",
      icon: <FiFileText />,
      link: "/dues",
    },
    {
      label: "Guests",
      icon: <BsFillPeopleFill />,
      link: "/register-guests",
    },
    {
      label: "Gate Pass",
      icon: <CiDeliveryTruck />,
      link: "/gate-pass",
    },

    {
      label: "Permits",
      icon: <MdOutlineConstruction />,
      link: "/work-permits",
    },

    {
      label: "Facilities",
      icon: <TbBuildingCottage />,
      link: "/facility-usage",
    },

    {
      label: "Concerns",
      icon: <GrUserWorker />,
      link: "/concerns",
    },

    {
      label: "Others",
      icon: <HiOutlineDotsHorizontal />,
      link: "/others",
    },
  ];

  return (
    <div className="text-neutral-700">
      <div className="text-center text-xs font-bold mt-4 text-blue-900">
        REQUESTS & SERVICES
      </div>
      <div className="flex items-center justify-around gap-2 flex-wrap p-2 rounded-xl ">
        {requestTypes.map((request, index) => (
          <div
            className="text-xs flex flex-col items-center justify-center w-20 h-20 bg-green-100 rounded-lg hover:bg-green-200 cursor-pointer"
            key={index}
            onClick={() => navigate(`${request.link}`)}
          >
            <div className="text-4xl">{request.icon}</div>
            <div className="text-xs font-semibold">{request.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RequestsSection;
