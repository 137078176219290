import React from "react";
import UserScreenWindow from "./UserScreenWindow";

export default function ConcernsRequestCard({ concern }) {
  return (
    <div>
      <div className="bg-white p-2 rounded-xl text-xs text-gray-600 hover:bg-blue-200 cursor-pointer relative">
        <div className="uppercase font-semibold ">{concern.concern}</div>
        <div>{concern.description}</div>
        <div>{concern.createdAt}</div>
        <div
          className={`absolute top-1 right-1 ${concern.status.toLowerCase()}`}
        >
          {concern.status}
        </div>
      </div>
    </div>
  );
}
