import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import SelectInput from "../Components/SelectInput";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function WorkPermitDetails({ application, setViewMode }) {
  const { id } = useParams();
  console.log(application.id);
  const currentUser = useSelector((state) => state.userObject);

  const [isLoading, setIsLoading] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(application);
  const [status, setStatus] = React.useState(application.status);
  const [comments, setComments] = React.useState([]);
  const [newComment, setNewComment] = React.useState("");
  const [files, setFiles] = React.useState({});

  useEffect(() => {
    const fetchApplication = async () => {
      getDocDataSnap("workPermits", application.id, (data) => {
        setFormData(data);
        setFiles(data.files);
        setStatus(data.status);
        setComments(data.comments || []);
        setIsLoading(false);
      });
    };
    fetchApplication();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleUpdateStatus = (e) => {
    setIsProcessing(true);
    setStatus(e.value);
  };

  const handleEditMode = async () => {
    setEditMode(!editMode);

    if (editMode) {
      try {
        await setDocData("workPermits", application.id, {
          ...formData,
          status,
          approvedAt: status === "Approved" ? new Date().toLocaleString() : "",
        });
        toast.success("Status updated successfully");
        setViewMode(false);
      } catch (error) {
        toast.error("Failed to update status");
      }
    }
  };

  const options = [
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
    { label: "Returned", value: "Returned" },
  ];

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleAddComment = async () => {
    if (!newComment) {
      return;
    }

    const comment = {
      message: newComment,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.email,
      name: currentUser.displayName,
    };

    const docObject = {
      ...formData,
      comments: [...comments, comment],
      updatedBy: currentUser.email,
      updatedByName: currentUser.displayName,
      updatedAt: new Date().toLocaleString(),
      lastUpdateType: "Comment Added",
    };

    try {
      await setDocData("workPermits", application.id, docObject);
      toast.success("Comment added successfully");
      setComments([...comments, comment]);
      setNewComment("");
    } catch (error) {
      toast.error("Failed to add comment");
    }
  };

  const handleDeleteComment = async (index) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      const newComments = comments.filter((comment, i) => i !== index);

      const docObject = {
        ...formData,
        comments: newComments,
        updatedBy: currentUser.email,
        updatedByName: currentUser.displayName,
        updatedAt: new Date().toLocaleString(),
        lastUpdateType: "Comment Deleted",
      };

      try {
        await setDocData("workPermits", application.id, docObject);
        toast.success("Comment deleted successfully");
        setComments(newComments);
      } catch (error) {
        toast.error("Failed to delete comment");
      }
    }
  };

  const filesArray =
    files !== undefined
      ? Object.keys(files).map((key) => {
          return {
            name: key,
            url: files[key],
          };
        })
      : [];

  return (
    <div className="text-gray-600 text-left bg-white w-full min-h-screen p-2 rounded-lg pt-8 relative flex flex-col lg:flex-row lg:gap-4">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>

      <div className="w-80">
        <div className="header">Work Permit Request Details</div>
        <div className="mt-8 space-y-2 [&>*]:border">
          <div className="flex items-center justify-between gap-8 max-w-[550px] p-2">
            <div className="w-60 font-bold">Lot Number</div>
            <div className="w-60 text-right">{formData?.identifier}</div>
          </div>

          <div className="flex items-center justify-between gap-8 max-w-[550px] p-2">
            <div className="w-60 font-bold">Request Type</div>
            <div className="w-60 text-right">{formData?.workPermitType}</div>
          </div>

          <div className="flex items-center justify-between gap-8 max-w-[550px] p-2">
            <div className="w-60 font-bold">Duration of Activities</div>
            <div className="w-60 text-right">{formData?.duration}</div>
          </div>

          <div className="flex items-center justify-between gap-8 max-w-[550px]  p-2">
            <div className="w-60 font-bold">Description of Work</div>
            <div className="w-60 text-right">{formData?.description}</div>
          </div>

          <div className="flex items-center justify-between gap-8 max-w-[550px]  p-2">
            <div className="w-60 font-bold">Vehicle Information</div>
            <div className="w-60 text-right">{formData?.vehicle}</div>
          </div>

          <div className="flex items-center justify-between gap-8 max-w-[550px]  p-2">
            <div className="w-60 font-bold">Workers List</div>
            <div className="w-60 text-right">{formData?.workers}</div>
          </div>

          <div className="flex items-center justify-between gap-8 max-w-[550px]  p-2">
            <div className="w-60 font-bold">Requested By</div>
            <div className="w-60 text-right">{formData?.name}</div>
          </div>

          <div className="flex items-center justify-between gap-8 max-w-[550px] p-2">
            <div className="w-60 font-bold">Status</div>
            <div className="w-60 text-right">{formData?.status}</div>
          </div>
        </div>

        {/* Documents here */}
        <div>
          {filesArray && (
            <div className="mt-4">
              <div className="text-blue-900 font-bold">Documents</div>
              {filesArray.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between gap-8 max-w-[550px] p-2 bg-gray-100 min-w-16 rounded-lg mt-2"
                >
                  <div className="w-60 font-bold">{file.name}</div>
                  <div className="bg-green-400 px-2 py-1 rounded-lg w-fit text-center font-semibold">
                    <a href={file.url} target="_blank" rel="noreferrer">
                      View Document
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Update Status here */}
        <div className="mt-8">
          <div className="flex items-center gap-4">
            <button className="blue-button" onClick={handleEditMode}>
              {editMode ? "Save" : "Update Status"}
            </button>

            {editMode && (
              <button className="underline text-xs" onClick={handleCancel}>
                Cancel
              </button>
            )}
          </div>
          {editMode && (
            <div className="max-w-[350px] mt-4">
              <SelectInput
                name="status"
                label="Update Status"
                options={options}
                value={options.find((option) => option.value === status)}
                onChange={handleUpdateStatus}
                required
              />
            </div>
          )}
        </div>
      </div>

      {/* Update Comments here */}
      <div className="w-full max-w-[1080px]">
        <div className="header mt-4">Comments</div>
        <div className="w-full">
          <textarea
            name="comment"
            rows={3}
            className="border rounded-lg w-full mt-4 p-2"
            id=""
            placeholder="Enter your comments here"
            onChange={handleChange}
            value={newComment}
          />
        </div>

        <button className="blue-button" onClick={handleAddComment}>
          Add Comment
        </button>

        <div className="mt-8">
          <div className="mt-4 max-w-[1440px]">
            {comments.map((comment, index) => (
              <div
                key={index}
                className="border p-2 rounded-lg bg-gray-100 shadow-sm relative mt-2"
              >
                {currentUser.email === comment.createdBy && (
                  <button
                    className="close-button"
                    onClick={() => handleDeleteComment(index)}
                  >
                    Delete
                  </button>
                )}
                <div className="text-xs font-semibold">{comment.name}</div>
                <div className="text-xs">{comment.message}</div>
                <div className="text-xs text-gray-400">{comment.createdAt}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
