import React from "react";
import InputBox from "../Components/InputBox";
import { useParams } from "react-router-dom";
import { setDocData } from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { v4 } from "uuid";

export default function TenantOccupantForm({ handleAddMode, tenant }) {
  const [formData, setFormData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const currentOccupants = tenant.occupantsName || [];
  const [assignedId, setAssignedId] = React.useState(v4());
  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      id: assignedId,
    };
    await setDocData("tenants", id, {
      occupantsName: [...currentOccupants, docObject],
    });
    toast.success("Occupant Added");
    setIsProcessing(false);
    handleAddMode();
  };

  return (
    <div className="text-gray-600 text-left small-form relative pt-8">
      <button className="close-button" onClick={handleAddMode}>
        Cancel
      </button>
      <div className="header">Add a Tenant Occupant</div>

      <form action="" onSubmit={handleSave}>
        <div className="flex flex-col items-stretch justify-start gap-4 mt-4">
          <InputBox
            label="First Name"
            name="firstName"
            type="text"
            required
            placeholder="Juan"
            onChange={handleChange}
          />
          <InputBox
            label="Middle Name"
            name="middleName"
            type="text"
            required
            placeholder="Santos"
            onChange={handleChange}
          />
          <InputBox
            label="Last Name"
            name="lastName"
            type="text"
            required
            placeholder="Dela Cruz"
            onChange={handleChange}
          />
          <InputBox
            label="Address"
            name="address"
            type="text"
            required
            placeholder="123 Main St., Makati City"
            onChange={handleChange}
          />
          <InputBox
            label="Birthday"
            name="birthday"
            type="date"
            required
            onChange={handleChange}
          />
          <InputBox
            label="Relationship to Lessee"
            name="relationship"
            type="text"
            required
            placeholder="Father"
            onChange={handleChange}
          />
          <button
            className="blue-button w-full disabled:bg-opacity-50"
            disabled={isProcessing}
          >
            {isProcessing ? "Adding Occupant..." : "Add Occupant"}
          </button>
        </div>
      </form>
    </div>
  );
}
