import React from "react";

const handleSchedule = (item) => {
  alert(`maintenance schedule is set for ${item.name}`);
};

export default function MaintenanceItemsCards({ item }) {
  return (
    <div className="p-2 rounded-md shadow-sm bg-white hover:bg-gray-200 cursor-pointer">
      <div className="flex flex-col items-start lg:flex-row lg:items-center gap-1 lg:gap-4">
        <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
          <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
            Type
          </div>
          <div className="font-bold px-1 text-blue-900">{item.type}</div>
        </div>

        <div className="flex flex-row items-center lg:flex-col lg:items-start w-full lg:w-60">
          <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
            Name
          </div>
          <div className="font-bold px-1 text-blue-900">{item.name}</div>
        </div>

        <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
          <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
            Frequency
          </div>
          <div className="font-bold px-1 text-blue-900">
            {item.frequency || "Not Set"}
          </div>
        </div>

        <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
          <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
            Next
          </div>
          <div className="font-bold px-1 text-blue-900">
            {item.nextSchedule || "Not Set"}
          </div>
        </div>

        <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
          <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
            Status
          </div>
          <div className="font-bold px-1 text-blue-900">
            {item.status || "Updated"}
          </div>
        </div>

        <div>
          {/* <button className="green-button" onClick={() => handleSchedule(item)}>
            Schedule for Next Maintenance
          </button> */}
        </div>
      </div>
    </div>
  );
}
