import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import GuestPassCard from "./GuestPassCard";
import PageModal from "../Dashboard/Components/PageModal";
import GuestPassDetailsPage from "./GuestPassDetailsPage";

export default function GuestPass() {
  const [passes, setPasses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);

  useEffect(() => {
    const fetchAllPasses = async () => {
      await getAllDocsSnap("guests", (data) => {
        setPasses(data);
        setLoading(false);
      });
    };
    fetchAllPasses();
  }, []);

  if (loading) {
    return <div>Loading..</div>;
  }

  const handleViewMode = (pass) => {
    setViewMode(true);
    setSelectedData(pass);
  };

  return (
    <div className="mt-4">
      <div className="header">Guest Pass Requests</div>
      <div className="space-y-2">
        {passes.map((pass, index) => (
          <div key={index} onClick={() => handleViewMode(pass)}>
            <GuestPassCard data={pass} />
          </div>
        ))}
      </div>

      {viewMode && (
        <PageModal>
          <GuestPassDetailsPage data={selectedData} setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}
