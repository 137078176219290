import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import Modal from "../Dashboard/Components/Modal";
import DeedofRestrictions from "./Billing/DeedofRestrictions";
import bpiLogo from '../assets/bpilogo.png';

function AdminUser() {
  const [showDeed, setShowDeed] = React.useState(false);

  const showDeedOfRestrictions = () => {
    setShowDeed(!showDeed);
  };

  return (
    <UserScreenWindow label="Administration">
      <div className="text-center mt-4 font-bold">
        HOA Bulletin of Information
      </div>
      <div className="p-2 bg-white rounded-xl space-y-8 mt-8">
        <div className="font-bold text-gray-600 text-sm">Announcements</div>

        <div className="font-bold text-gray-600 text-sm">
          Rules and Regulations
        </div>

        <div className="font-bold text-gray-600 text-sm ">
          <button className="underline" onClick={showDeedOfRestrictions}>
            Deed of Restrictions
          </button>
        </div>

        <div className="font-bold text-gray-600 text-sm">
          Downloadable Forms
        </div>

        <div>
          <div className="font-bold text-gray-600 text-sm mb-8">
            Payment Channels
          </div>
          <div>
            <img src={bpiLogo} alt="" className="w-24"/>
          </div>
          <div>Bank of Philippine Islands</div>
          <div>Bank Account Number: 0091 0619 96</div>
        </div>
      </div>

      <div>
        {showDeed && (
          <Modal>
            <DeedofRestrictions
              showDeedOfRestrictions={showDeedOfRestrictions}
            />
          </Modal>
        )}
      </div>
    </UserScreenWindow>
  );
}

export default AdminUser;
