import React from "react";

function SupplierContactCard({ supplier }) {
  return (
    <div className="text-gray-600 text-xs flex flex-col items-center justify-center">
      <img src={supplier.logo} className="w-16 min-h-26 object-cover" alt="" />
      <div className="font-bold text-center text-blue-900">{supplier.name}</div>
      <div className="text-center font-bold">
        <a href={`tel:${supplier.phone}`}>{supplier.phone}</a>
      </div>
      <div className="text-center">{supplier.address}</div>
      <button className="blue-button mt-4">
        <a href={`tel:${supplier.phone}`}>Call</a>
      </button>
    </div>
  );
}

export default SupplierContactCard;
