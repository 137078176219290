import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import Modal from "../Components/Modal";
import AddResidentOccupantForm from "./AddResidentOccupantForm";
import ResidentOccupantCard from "./ResidentOccupantCard";
import EmptyPage from "../Components/EmptyPage";
import PageModal from "../Components/PageModal";
import ResidentOccupantDetails from "./ResidentOccupantDetails";

export default function OccupantsInformationPage() {
  const { id } = useParams();
  const [occupants, setOccupants] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [addMode, setAddMode] = useState(false);
  const [occupantId, setOccupantId] = useState("");
  const [selectedOccupant, setSelectedOccupant] = useState({});
  const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    const fetchOccupants = async () => {
      await getDocDataSnap("residents", id, (data) => {
        setOccupants(data.occupantsName || []);
        setIsLoading(false);
      });
    };
    fetchOccupants();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleViewOccupant = (occupant) => {
    setViewMode(!viewMode);
    setOccupantId(occupant.id);
    setSelectedOccupant(occupant);
    console.log(occupant.id);
  };

  return (
    <div className="text-left text-gray-600">
      <div className="header">Occupants Information</div>

      <div className="mt-4">
        <button className="blue-button" onClick={handleAddMode}>
          Add Occupant
        </button>
      </div>

      {/* mapped occupants here */}
      <div className="mt-4 space-y-2">
        {occupants.map((occupant, index) => (
          <div key={index} onClick={() => handleViewOccupant(occupant)}>
            <ResidentOccupantCard occupant={occupant} />
          </div>
        ))}

        {occupants.length === 0 && (
          <EmptyPage>
            <div className="text-center font-bold text-xl mb-8">
              Click Add Occupant to start adding data
            </div>
          </EmptyPage>
        )}
      </div>

      {addMode && (
        <Modal>
          <AddResidentOccupantForm
            handleAddMode={handleAddMode}
            occupants={occupants}
          />
        </Modal>
      )}

      {viewMode && (
        <PageModal>
          <ResidentOccupantDetails
            occupantId={occupantId}
            setViewMode={setViewMode}
            occupant={selectedOccupant}
            occupants={occupants}
          />
        </PageModal>
      )}
    </div>
  );
}
