import React from "react";
import HoaDirectory from "../User/HoaDirectory";
import ProfilePhotoWithDetails from "../User/ProfilePhotoWithDetails";
import SOS from "../User/SOS";

function Contacts() {
  return (
    <div className="text-blue-900 font-bold">
      <div>Emergency Contact Information and HOA Directory</div>
      <div className="flex items-center justify-end w-full gap-2 text-right">
        <button className="blue-button">Add</button>
        <button className="blue-button">Edit</button>
      </div>
      <div>
        <div>
          <div className="font-bold text-blue-900 text-sm text-center mt-8 header">
            HOA Directory
          </div>
          <div className="font-bold text-gray-600 text-sm mt-8 header text-center">
            Tali Beach Hotline Number
          </div>
          <div className="mt-4 text-center">
            <a href="tel:09270368277">0927 036 8277</a>
          </div>

          <div className="font-bold text-gray-600 text-sm header mt-8 text-center">
            Tali Beach Makati Office
          </div>
          <div className="flex flex-col text-center mt-4">
            <a href="tel:09151782646">0915 178 2646</a>
            <a href="tel:0288928015">02 8892 8015</a>
          </div>

          <div className="font-bold text-gray-600 text-sm mt-4 header text-center">
            Admin Maintenance
          </div>
          <div className="flex flex-row items-center justify-center gap-2 mt-8">
            <ProfilePhotoWithDetails
              image={""}
              name="Engr. Arjay Elumbra"
              contactNumber={"0916 108 5814"}
            />
            <ProfilePhotoWithDetails
              image={""}
              name="Engr. Rene Espenida"
              contactNumber={"0930 887 2501"}
            />
          </div>

          <div className="font-bold text-gray-600 text-sm mt-8 header text-center">
            Tali Beach Corporate Security and Safety Coordinator
          </div>
          <ProfilePhotoWithDetails
            name="Mr. Joevanni Cialbo"
            contactNumber="0905 338 7440"
          />
        </div>
      </div>

      <div>
        <div className=" rounded-xl p-2 mt-4">
          <div className="text-center text-sm font-bold text-blue-900 mt-8">
            Emergency Hotlines
          </div>
          <div className="font-bold text-center text-green-800">
            Tap the numbers below to call
          </div>

          <div className="text-sm font-bold text-gray-600 mt-8">
            PNP Nasugbu
          </div>
          <div className="flex flex-col">
            <a href="tel:09245001199">0924 500 1199</a>
            <a href="tel:09985985697">0998 598 5697</a>
          </div>

          <div className="text-sm font-bold text-gray-600 mt-8">
            Municipality of Nasugbu
          </div>
          <a href="tel:09175234496">0917 523 4496</a>

          <div className="text-sm font-bold text-gray-600 mt-8">
            Bureau of Fire Protection - Nasugbu
          </div>
          <div className="flex flex-col">
            <a href="tel:0434160125">043 416 0125</a>
            <a href="tel:09954465073">0995 446 5073</a>
          </div>

          <div className="text-sm font-bold text-gray-600 mt-8">
            Coast Guard Substation - Nasugbu
          </div>
          <a href="tel:09687058583">0968 705 8583</a>

          <div className="text-sm font-bold text-gray-600 mt-8">
            Batelec Hotlines
          </div>
          <div className="flex flex-col">
            <a href="tel:09272456464">0927 245 6464</a>
            <a href="tel:09476364899">0947 636 4899</a>
            <a href="tel:09176397185">Trunkline - 0917 639 7185</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
