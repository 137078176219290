import SelectInput from "../Dashboard/Components/SelectInput";
import React, { useEffect } from "react";
import {
  getDocDataSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { setDoc } from "firebase/firestore";
import toast from "react-hot-toast";

export default function GuestPassDetailsPage({ data, setViewMode }) {
  const [newStatus, setNewStatus] = React.useState("");
  const [formData, setFormData] = React.useState(data);
  const [updateHappened, setUpdateHappened] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [comments, setComments] = React.useState([]);
  const [newComment, setNewComment] = React.useState("");

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchData = async () => {
      await getDocDataSnap("guests", data.id, (data) => {
        setFormData(data);
        setComments(data.comments || []);
      });
    };
    fetchData();
  }, []);

  const status = [
    "pending",
    "approved",
    "rejected",
    "admitted",
    "completed",
    "returned",
  ];
  const statusOptions = status.map((item) => ({ value: item, label: item }));

  const handleSelect = (selected) => {
    setUpdateHappened(true);
    setNewStatus(selected.value);
  };

  const handleUpdateStatus = async () => {
    const docObject = {
      status: newStatus,
      updatedBy: currentUser.uid,
      updatedName: currentUser.displayName,
      updatedEmail: currentUser.email,
      updateType: "Status is updated to " + newStatus,
    };

    await setDocData("guests", data.id, docObject);
    toast.success("Status Updated Successfully");
    setUpdateHappened(false);
  };

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleSubmit = async () => {
    const docObject = {
      comments: [
        ...comments,
        {
          message: newComment,
          createdAt: new Date().toLocaleString(),
          createdBy: currentUser.displayName,
        },
      ],
    };
    await setDocData("guests", data.id, docObject);
    toast.success("Comment Added Successfully");
    setNewComment("");
  };

  return (
    <div className="bg-white w-full min-h-screen rounded-lg p-4 pt-8 relative flex flex-col lg:flex-row items-start gap-2 lg:gap-4">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>

      <div className="min-w-80">
        <div className="header">Guest Pass Request Details</div>
        <div className="flex items-center justify-between">
          <div>Lot Number</div>
          <div>{data.linkedIdentifiers[0]}</div>
        </div>

        <div className="flex items-center justify-between">
          <div>Number of Guests</div>
          <div className="capitalize">{data.guests.length}</div>
        </div>

        <div className="flex flex-col items-start mb-4 mt-2">
          <div>Guests Details</div>
          <div>
            {data.guests.map((guest) => (
              <div className="bg-gray-100 p-2 rounded-md mt-2 text-justify">
                {guest.name} - {guest.email} - {guest.plateNumber}
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div>Date of Arrival</div>
          <div>{data.date}</div>
        </div>

        {/* <div className="flex items-center justify-between">
          <div>Request Date & Time</div>
          <div>
            {new Date(
              data.requestDateTime?.seconds * 1000
            ).toLocaleDateString()}{" "}
            {new Date(
              data.requestDateTime?.seconds * 1000
            ).toLocaleTimeString()}
          </div>
        </div> */}

        <div className="flex items-center justify-between">
          <div>Requestor Email</div>
          <div>{data.requestedBy}</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="">Status</div>
          <div className={`capitalize ${formData.status}`}>
            {formData.status}
          </div>
        </div>

        <div className="mt-8">
          <div className="header">Update Status</div>

          <div>
            <SelectInput
              name={"status"}
              placeholder={"Select Status"}
              options={statusOptions}
              onChange={(selected) => handleSelect(selected)}
            />
            <button
              className="blue-button mt-4"
              disabled={!updateHappened}
              onClick={handleUpdateStatus}
            >
              Update Status
            </button>
          </div>
        </div>
      </div>

      <div className="w-full max-w-[1080px]">
        <div className="header">Comments</div>
        <div className="w-full">
          {/* <button className="blue-button" onClick={handleAddMode}>
            Add Comment
          </button> */}
          <textarea
            name="newComment"
            className="mt-4 rounded-lg p-2 border w-full"
            id=""
            onChange={handleChange}
            placeholder="Add Comment Here"
            value={newComment}
          ></textarea>
          <div className="text-right">
            <button className="blue-button" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>

        <div>
          {comments.map((comment, index) => (
            <div className="comment mt-2">
              <div>{comment.createdBy}</div>
              <div>{comment.message}</div>
              <div>{comment.createdAt}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
