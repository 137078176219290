import React, { useEffect, useState } from "react";
import {
  addNewDoc,
  getDocData,
  setDocData,
  storage,
} from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import NoImage from "../assets/noimageuploaded.png";

function GeneralSetup() {
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [company, setCompany] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState("");
  const [activeUpload, setActiveUpload] = useState("");

  const currentUser = useSelector((state) => state.userObject);

  const id = "7Dw6sBMQrHLSiHoirHdN";

  useEffect(() => {
    const fetchCompanyData = async () => {
      const data = await getDocData("company", id);
      setFormData(data);

      setIsLoading(false);
    };
    fetchCompanyData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpload = async (e, fileName) => {
    e.preventDefault();
    const file = e.target.files[0];
    setIsSubmitting(true);

    const storageRef = ref(storage, `company/${id}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setActiveUpload(fileName);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        toast.error("Error uploading file");
        setIsSubmitting(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          setFormData((prev) => ({ ...prev, [fileName]: downloadURL }));
          toast.success(`${fileName} Uploaded Successfully`);
          setIsSubmitting(false);
        });
      }
    );
    setIsSubmitting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const docObject = {
      ...formData,

      lastUpdated: new Date(),
      user: currentUser.email,
    };

    await setDocData("company", id, docObject);
    setEditMode(false);
    setIsSubmitting(false);
    toast.success("Company data updated");
  };

  const companyData = [
    {
      label: "Company Name",
      name: "companyName",
      type: "text",
      placeholder: "Company Name",
      onChange: handleChange,
    },
    {
      label: "Company Address",
      name: "companyAddress",
      type: "text",
      placeholder: "Company Address",
      onChange: handleChange,
    },
    {
      label: "Company Email",
      name: "companyEmail",
      type: "email",
      placeholder: "Company Email",
      onChange: handleChange,
    },
    {
      label: "Company Phone",
      name: "companyPhone",
      type: "tel",
      placeholder: "Company Phone",
      onChange: handleChange,
    },
    {
      label: "Company Logo",
      name: "companyLogo",
      type: "file",
      placeholder: "Company Logo",
      onChange: handleUpload,
    },
    {
      label: "Company Website",
      name: "companyWebsite",
      type: "text",
      placeholder: "Company Website",
      onChange: handleChange,
    },
    {
      label: "Company Description",
      name: "companyDescription",
      type: "text",
      placeholder: "Company Description",
      onChange: handleChange,
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  return (
    <div className="text-left min-w-[800px]">
      <form onSubmit={handleSubmit}>
        <div className="flex items-center gap-4 justify-between mb-8">
          <div className="font-bold text-blue-900">Setup Your Company</div>
          <div
            className="underline text-sm cursor-pointer"
            onClick={handleEdit}
          >
            {editMode ? "Cancel Edit" : "Edit"}
          </div>
        </div>
        <div className=" text-gray-600">
          <div className="space-y-4">
            {companyData.map((item, index) => (
              <div key={index} className="flex flex-col gap-1">
                <label className="text-xs font-bold">{item.label}</label>
                {item.type === "file" ? (
                  <div className="flex flex-col items-start justify-normal gap-4">
                    {editMode ? (
                      <>
                        <input
                          className="border p-2"
                          type={item.type}
                          name={item.name}
                          placeholder={item.placeholder}
                          onChange={(e) => item.onChange(e, item.name)}
                        />
                        <img
                          src={formData[item.name] || NoImage}
                          alt="logo"
                          className="h-20 w-20"
                        />
                        <button
                          className="underline text-xs cursor-pointer"
                          onClick={() =>
                            setFormData((prev) => ({
                              ...prev,
                              [item.name]: "",
                            }))
                          }
                        >
                          Remove Logo
                        </button>
                        {progress > 0 && activeUpload === item.name && (
                          <progress value={progress} max="100" />
                        )}
                      </>
                    ) : formData[item.name] ? (
                      <img
                        src={formData[item.name]}
                        alt="logo"
                        className="h-20 w-20"
                      />
                    ) : (
                      <div className="text-xs itali">
                        No Company Logo Uploaded
                      </div>
                    )}
                  </div>
                ) : (
                  <input
                    className="border p-2 rounded-lg "
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    onChange={item.onChange}
                    value={formData[item.name]}
                    disabled={!editMode}
                  />
                )}
              </div>
            ))}
          </div>

          {editMode && (
            <button
              className="bg-blue-900 text-white p-2 rounded-lg mt-4"
              type="submit"
              onClick={handleSubmit}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default GeneralSetup;
