import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  addNewDoc,
  setDocData,
  queryAllDocsByFieldSnap,
} from "../../../utils/firebase/Firebase.utils";
import InventoryFormAdd from "./InventoryFormAdd";
import AdditionCard from "./TransactionCard";
import TransactionCard from "./TransactionCard";

export default function ItemDetailsPage({ data, setViewMode }) {
  const [addMode, setAddMode] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [actionType, setActionType] = React.useState("");
  const [transactions, setTransactions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      await queryAllDocsByFieldSnap(
        "inventoryTransactions",
        "itemId",
        "==",
        data.id,
        (data) => {
          setTransactions(data || []);
          setLoading(false);
        }
      );
    };
    fetchTransactions();
  }, []);

  const handleAddMode = (action) => {
    setAddMode(true);
    setActionType(action);
  };

  return (
    <div className="page relative flex flex-col lg:flex-row items-start gap-4">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>

      <div className="lg:min-w-96">
        <div className="header">Item Details</div>
        <div className="font-bold">{data.itemName}</div>
        <div className="flex items-center gap-4 mt-4">
          <button className="blue-button" onClick={() => handleAddMode("add")}>
            Record Addition
          </button>
          <button
            className="blue-button"
            onClick={() => handleAddMode("issuance")}
          >
            Issue Inventory
          </button>
        </div>

        <div className="mt-4">
          {addMode && actionType === "add" ? (
            <div className="lg:w-80">
              <InventoryFormAdd id={data.id} setAddMode={setAddMode} />
            </div>
          ) : null}
        </div>
      </div>

      <div className="w-full">
        <div className="header">Transaction</div>
        <div>{loading ? <div>Loading..</div> : null}</div>

        {transactions.map((transaction, index) => (
          <div key={index} className="border p-2 mt-2">
            <TransactionCard data={transaction} />
          </div>
        ))}
      </div>
    </div>
  );
}
