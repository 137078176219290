import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  setDocData,
  getDocDataSnap,
  storage,
} from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import NoPhoto from "../../assets/blankphoto.webp";
import InputBox from "../Components/InputBox";
import ResidentOccupantInformationPage from "./ResidentOccupantInformationPage";
import ResidentOccupantDocuments from "./ResidentOccupantDocuments";
import toast from "react-hot-toast";

export default function ResidentOccupantDetails({ setViewMode, occupantId }) {
  const [activeLink, setActiveLink] = useState("Personal Information");
  const { id } = useParams();
  const [editMode, setEditMode] = React.useState(false);
  const links = ["Personal Information", "Documents"];
  const [progress, setProgress] = useState(0);
  const [occupant, setOccupant] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [occupants, setOccupants] = React.useState([]);

  useEffect(() => {
    const fetchOccupants = async () => {
      await getDocDataSnap("residents", id, (data) => {
        setOccupants(data.occupantsName || []);
        setOccupant(data.occupantsName.find((o) => o.id === occupantId));
        setIsLoading(false);
      });
    };
    fetchOccupants();
  }, []);

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await setDocData("residents", id, {
            occupantsName: occupants.map((occupant) => {
              if (occupant.id === occupantId) {
                occupant.primaryPhoto = downloadURL;
              }
              return occupant;
            }),
          });
          toast.success("Photo uploaded successfully");
          setEditMode(false);
        });
      }
    );
  };

  return (
    <div className="bg-gray-100 w-full min-h-screen relative rounded-lg">
      <div className="header px-8 py-4">Owner Occupant Details</div>

      <div className="mt-4">
        <button className="close-button" onClick={() => setViewMode(false)}>
          Close
        </button>
      </div>

      <div className="bg-blue-900 p-8 flex items-center gap-4 flex-wrap">
        <img
          src={occupant.primaryPhoto || NoPhoto}
          alt="Primary Photo"
          className="w-60 aspect-square object-cover object-top rounded-full"
        />
        <div className="font-bold uppercase text-white text-lg">
          {occupant.firstName} {occupant.lastName}
        </div>
      </div>

      <div className="p-8">
        <div className="mt-4">
          {editMode && (
            <div className="flex flex-col gap-2 mt-4 ">
              <input type="file" onChange={handleUpload} />
              <progress value={progress} max="100" />
            </div>
          )}
          <button
            className={editMode ? "underline" : "blue-button"}
            onClick={handleEditMode}
          >
            {editMode ? "Cancel Edit" : "Edit Photo"}
          </button>
        </div>

        <div className="links-container mt-4">
          {links.map((link, index) => (
            <div
              onClick={() => setActiveLink(link)}
              className={
                activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
              }
              key={index}
            >
              {link}
            </div>
          ))}
        </div>

        <div className="mt-4 bg-white rounded-lg p-2">
          {activeLink === "Personal Information" && (
            <ResidentOccupantInformationPage occupantId={occupantId} />
          )}

          {activeLink === "Documents" && (
            <ResidentOccupantDocuments occupantId={occupantId} />
          )}
        </div>
      </div>
    </div>
  );
}
