import React from "react";

function CheckBox({ name, label, onChange, checked, ...otherprops }) {
  return (
    <div className="flex items-center justify-start gap-2">
      <input
        name={name}
        type="checkbox"
        {...otherprops}
        onChange={onChange}
        // checked={checked}
      />
      <label className="capitalize">{label}</label>
    </div>
  );
}

export default CheckBox;
