import React from "react";

export default function ConcernsCard({ concern }) {
  return (
    <div className="bg-white gap-2 rounded-md shadow-sm p-2 text-gray-600 flex flex-col lg:flex-row lg:items-center hover:bg-gray-200 cursor-pointer flex-wrap">
      <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
        <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
          Lot Number
        </div>
        <div className="font-bold px-1 text-blue-900">{concern.identifier}</div>
      </div>

      <div className="flex flex-row items-center lg:flex-col lg:items-start w-48">
        <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1">
          Concern
        </div>
        <div className="font-bold px-1 ">{concern.concern}</div>
      </div>

      <div className="flex flex-row items-center lg:flex-col lg:items-start w-96 lg:w-80">
        <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1">
          Description
        </div>
        <div className="font-bold px-1 ">{concern.description}</div>
      </div>

      <div className="flex flex-row items-center lg:flex-col lg:items-start w-80">
        <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1">
          Filed on
        </div>
        <div className="font-bold px-1 ">{concern.createdAt}</div>
      </div>

      <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
        <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1">
          Filed By
        </div>
        <div className="font-bold px-1 ">{concern.user}</div>
      </div>

      <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
        <div className="rounded-full text-[8px] bg-gray-100 w-24 px-1">
          Number of Days Resolved
        </div>
        <div className="font-bold px-1 ">
          {concern.status !== "Resolved"
            ? "0"
            : Math.ceil(
                (new Date(concern.resolvedAt) - new Date(concern.createdAt)) /
                  (1000 * 60 * 60 * 24)
              )}
        </div>
      </div>

      <div className={`capitalize ${concern.status.toLowerCase()}`}>
        {concern.status}
      </div>
    </div>
  );
}
