import React from "react";
import { FaHouse } from "react-icons/fa6";
import { IoPersonCircle } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { GiResize } from "react-icons/gi";

function ResidentsCard({ resident }) {
  const { identifier, owner1, lotSize, email, phoneNumber } = resident;

  return (
    <div className="bg-white cursor-pointer hover:border hover:bg-gray-200 p-4 min-h-20 text-xs rounded-md shadow-sm">
      <div className="text-gray-600 flex items-start gap-2 text-left flex-wrap">
        <div className="flex flex-col items-start justify-start">
          <div className="flex items-start justify-start gap-2">
            <FaHouse className="text-xl text-green-600" />
            <div className="w-60 font-bold text-blue-900"> {identifier}</div>
          </div>
          {/* <div className="text-gray-600 text-xs">Lot Number</div> */}
        </div>

        <div className="flex gap-2 items-start justify-start">
          <IoPersonCircle className="text-xl text-green-600" />

          <div className="w-80  text-gray-600"> {owner1}</div>
        </div>

        <div className="flex gap-2 items-start justify-start">
          <MdEmail className="text-xl text-green-600" />
          <div className="w-60  text-gray-600"> {email}</div>
        </div>

        <div className="flex gap-2 items-start justify-start">
          <GiResize className="text-xl text-green-600" />
          <div className="w-80  text-gray-600"> {lotSize} sqms</div>
        </div>
      </div>
    </div>
  );
}

export default ResidentsCard;
