import React from "react";
import UserScreenWindow from "./UserScreenWindow";

export default function ConcernConfirmationPage() {
  return (
    <UserScreenWindow label={"Concern Confirmation"}>
      <div>
        <div>
          Thank you for filing your concern, we will update you as soon as
          possible.
        </div>
      </div>
    </UserScreenWindow>
  );
}
