import React from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

function Analytics() {
  // Data for Electricity Usage (Line Chart)
  const lineChartData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Electricity Usage (kWh)",
        data: [500, 600, 700, 800, 750, 850],
        backgroundColor: "rgba(66, 153, 225, 0.2)",
        borderColor: "rgba(66, 153, 225, 1)",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
    ],
  };

  // Data for Maintenance Cost (Bar Chart)
  const barChartData = {
    labels: ["Vehicle", "Genset", "Plumbing", "Painting"],
    datasets: [
      {
        label: "Maintenance Cost (₱)",
        data: [12000, 8000, 4000, 7000],
        backgroundColor: "rgba(30, 64, 175, 0.7)",
        borderColor: "rgba(30, 64, 175, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Data for Security Cost (Line Chart)
  const securityCostData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Security Cost (₱)",
        data: [10000, 12000, 13000, 12500, 14000, 13500],
        backgroundColor: "rgba(245, 158, 11, 0.2)",
        borderColor: "rgba(245, 158, 11, 1)",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
    ],
  };

  // Data for Security Incidents (Bar Chart)
  const securityIncidentsData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Security Incidents",
        data: [5, 7, 4, 6, 8, 5],
        backgroundColor: "rgba(220, 38, 38, 0.7)",
        borderColor: "rgba(220, 38, 38, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Data for Personnel Cost (Doughnut Chart)
  const personnelCostData = {
    labels: [
      "Security Personnel",
      "Maintenance Personnel",
      "Administrative Personnel",
    ],
    datasets: [
      {
        label: "Personnel Cost (₱)",
        data: [50000, 35000, 25000],
        backgroundColor: ["#1E40AF", "#10B981", "#F59E0B"],
        borderWidth: 1,
      },
    ],
  };

  // Data for Dues Collection (Bar Chart)
  const duesCollectionData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Dues Collected (₱)",
        data: [15000, 20000, 18000, 19000, 21000, 22000],
        backgroundColor: "rgba(34, 197, 94, 0.7)",
        borderColor: "rgba(34, 197, 94, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Data for Total Expenses (Bar Chart)
  const totalExpensesData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Total Expenses (₱)",
        data: [12000, 17000, 16000, 15000, 18000, 20000],
        backgroundColor: "rgba(239, 68, 68, 0.7)",
        borderColor: "rgba(239, 68, 68, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="flex-1 p-8 bg-gray-100">
      <header className="flex justify-between items-center mb-8">
        <h1 className="lg:text-3xl font-semibold text-blue-900 text-sm">Analytics</h1>
      </header>

      {/* Charts Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Line Chart: Electricity Usage */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-600 mb-4">
            Electricity Usage Over Time
          </h2>
          <Line data={lineChartData} />
        </div>

        {/* Bar Chart: Maintenance Cost */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-600 mb-4">
            Maintenance Cost by Category (₱)
          </h2>
          <Bar data={barChartData} />
        </div>

        {/* Line Chart: Security Cost */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-600 mb-4">
            Security Cost Over Time (₱)
          </h2>
          <Line data={securityCostData} />
        </div>

        {/* Bar Chart: Security Incidents */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-600 mb-4">
            Security Incidents Per Month
          </h2>
          <Bar data={securityIncidentsData} />
        </div>

        {/* Doughnut Chart: Personnel Cost Distribution */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-600 mb-4">
            Personnel Cost Distribution (₱)
          </h2>
          <Doughnut data={personnelCostData} />
        </div>

        {/* Bar Chart: Dues Collection vs Total Expenses */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-600 mb-4">
            Dues Collection vs Total Expenses (₱)
          </h2>
          <Bar
            data={{
              labels: ["January", "February", "March", "April", "May", "June"],
              datasets: [
                {
                  label: "Dues Collected (₱)",
                  data: duesCollectionData.datasets[0].data,
                  backgroundColor: "rgba(34, 197, 94, 0.7)",
                  borderColor: "rgba(34, 197, 94, 1)",
                  borderWidth: 1,
                },
                {
                  label: "Total Expenses (₱)",
                  data: totalExpensesData.datasets[0].data,
                  backgroundColor: "rgba(239, 68, 68, 0.7)",
                  borderColor: "rgba(239, 68, 68, 1)",
                  borderWidth: 1,
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Analytics;