import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import InputBox from "../Dashboard/Components/InputBox";
import { storage, setDocData } from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default function Complaints() {
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  return (
    <UserScreenWindow label="Complaints">
      <div className="space-y-8">
        <div className="header">Complaints</div>

        <InputBox
          name="complaint"
          label="Enter Nature of Complaint"
          type="text"
          placeholder="Enter your complaint here"
        />

        <div className="flex flex-col">
          <label className="text-blue-900 text-xs uppercase font-bold">
            Enter Details of Your Complaint
          </label>
          <textarea
            className="border border-gray-300 px-4 py-2 rounded-lg"
            name="description"
            placeholder="Enter your complaint details here"
            rows="8"
            id=""
          ></textarea>
        </div>
      </div>
    </UserScreenWindow>
  );
}
