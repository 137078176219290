import React from "react";

export default function DeedofRestrictions({ showDeedOfRestrictions }) {
  const filesource =
    "https://firebasestorage.googleapis.com/v0/b/elegho-eaf25.appspot.com/o/admin%2FdeedOfRestrictions%2FDeed%20of%20restrictions.pdf?alt=media&token=8d5a949c-8736-48cc-b2d7-61e1be20bcaf";
  return (
    <div className="small-form h-[500px] overflow-hidden relative">
      <div>
        <button className="close-button" onClick={showDeedOfRestrictions}>
          Close
        </button>
      </div>
      <div className="header pt-8">Deed of Restrictions</div>
      <div className="w-full h-full">
        <object
          data={filesource}
          type="application/pdf"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <embed
            src={filesource}
            type="application/pdf"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </object>
      </div>
    </div>
  );
}
