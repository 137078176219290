import React, { useEffect } from "react";
import {
  getDocDataSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function IncidentReportDetails({ data, setViewMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = React.useState(data);
  const [editMode, setEditMode] = React.useState(false);
  const [newComment, setNewComment] = React.useState("");
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [comments, setComments] = React.useState(data.comments || []);

  useEffect(() => {
    const fetchData = async () => {
      const doc = await getDocDataSnap("incidentReports", data.id, (doc) => {
        setFormData(doc);
        setComments(doc.comments || []);
      });
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    setIsProcessing(true);
    const newComments = [
      ...comments,
      {
        message: newComment,
        createdByName: currentUser.displayName,
        createdEmail: currentUser.email,
        createdBy: currentUser.uid,
        createdAt: new Date().toDateString(),
      },
    ];

    await setDocData("incidentReports", data.id, {
      comments: newComments,
    });

    setComments(newComments);
    setNewComment("");
    setIsProcessing(false);
    toast.success("Comment added successfully");
  };

  return (
    <div className="page relative flex items-start gap-4 lg:flex-row flex-col">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>

      {/* left side */}
      <div>
        <div className="header">Incident Report Details</div>

        <div className="lg:w-96 flex items-center justify-between">
          <div>Type of Incident</div>
          <div>
            <input
              type="text"
              name="incidentType"
              className="text-right"
              placeholder="Type of Incident"
              value={formData.incidentType}
              disabled={!editMode}
            />
          </div>
        </div>

        <div className="lg:w-96 flex items-center justify-between">
          <div>Location</div>
          <div>
            <input
              type="text"
              name="location"
              className="text-right"
              placeholder="Type of Incident"
              value={formData.location}
              disabled={!editMode}
            />
          </div>
        </div>

        <div className="lg:w-96 flex items-center justify-between">
          <div>Police Reported?</div>
          <div>
            <input
              type="text"
              name="policeReported"
              className="text-right"
              placeholder="Type of Incident"
              value={formData.policeReported}
              disabled={!editMode}
            />
          </div>
        </div>

        <div className="lg:w-96 flex items-center justify-between">
          <div>Status</div>
          <div>
            <input
              type="text"
              name="incidentType"
              className="text-right w-52"
              placeholder="Type of Incident"
              value={formData.status}
              disabled={!editMode}
            />
          </div>
        </div>

        <div className="lg:w-96 flex-col items-startbetween">
          <div>Description</div>
          <div>
            <textarea
              rows="15"
              type="text"
              name="incidentType"
              className="text-left w-full p-2 border rounded-lg"
              placeholder="Description"
              value={formData.description}
              disabled={!editMode}
            />
          </div>
        </div>
      </div>

      {/* right side */}
      <div className="w-full">
        <div className="header">Comments</div>

        <div>
          <textarea
            name="comment"
            rows="6"
            className="rounded-lg p-2 border w-full"
            placeholder="Add a comment"
            id=""
            onChange={(e) => setNewComment(e.target.value)}
            value={newComment}
          ></textarea>
          <button className="blue-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>

        <div className="mt-8">
          {comments.map((comment, index) => (
            <div className="comment" key={index}>
              <div>{comment.createdByName}</div>
              <div>{comment.message}</div>
              <div>{comment.createdAt}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
