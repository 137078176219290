import React from "react";

function PersonViewer({ person, setPersonViewMode }) {
  return (
    <div className="small-form relative">
      <button onClick={() => setPersonViewMode(false)} className="close-button">
        Close
      </button>
      <div className="pt-8">
        <img
          src={person.photo ? person.photo : null}
          className="w-20 h-20 object-contain"
          alt=""
        />
      </div>
      <div className="header">{person.name}</div>
      <div className="px-4 mt-4 text-xs mb-4">
        <div>Contact Number:{person.phone}</div>
        <div>Address: {person.address}</div>
        <div>Birthday: {person.bday}</div>
      </div>

      <div className="header">Identification</div>
      <a className="underline" href={person.identification} target="_blank">
        Click Here
      </a>
    </div>
  );
}

export default PersonViewer;
