import React from 'react'
import HistoryInformation from '../HistoryInformation'

export default function HistoryInformationPage() {
  return (
    <div>
      <HistoryInformation />
    </div>
  )
}
