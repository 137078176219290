import React from "react";

export default function ResidentOccupantCard({ occupant }) {
  return (
    <div className="bg-white rounded-md shadow-sm hover:bg-gray-200 cursor-pointer p-2 ">
      <div className="font-bold">
        {occupant.firstName} {occupant.middleName} {occupant.lastName}
      </div>
      <div>{occupant.relationship}</div>
    </div>
  );
}
