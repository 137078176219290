import React, { useEffect } from "react";
import NoPhoto from "../../assets/blankphoto.webp";
import {
  setDocData,
  storage,
  getDocDataSnap,
} from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";
import InputBox from "../Components/InputBox";
import OccupantFormData from "./OccupantFormData";
import OccupantDocuments from "./OccupantDocuments";

export default function TenantOccupantDetails({
  tenantId,
  occupantId,
  tenant,
  handleViewMode,
}) {
  const [formData, setFormData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [progress, setProgress] = React.useState(0);
  const [occupant, setOccupant] = React.useState({});
  const [editMode, setEditMode] = React.useState(false);
  const [activeLink, setActiveLink] = React.useState("Information");

  useEffect(() => {
    const fetchOccupant = async () => {
      getDocDataSnap("tenants", tenantId, (tenant) => {
        const occupant = tenant.occupantsName.find(
          (item) => item.id === occupantId
        );
        setOccupant(occupant);
        setFormData(occupant);
        setIsLoading(false);
      });
    };
    fetchOccupant();
  }, []);

  const handleUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (!file) {
      toast.error("No file selected");
      return;
    }

    const storageRef = ref(storage, `tenants/${tenant.id}/${occupant.id}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData((prev) => ({ ...prev, primaryPhoto: downloadURL }));
          setDocData("tenants", tenantId, {
            occupantsName: tenant.occupantsName.map((item) =>
              item.id === occupant.id
                ? { ...item, primaryPhoto: downloadURL }
                : item
            ),
          });
        });
      }
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleEditMode = () => {
    setEditMode(!editMode);

    if (editMode) {
      setFormData(occupant);
    }
  };

  const links = ["Information", "Documents"];

  const handleSubmit = () => {};

  return (
    <div className="relative pt-8 m-2 bg-gray-100 w-full min-h-screen rounded-lg ">
      <button className="close-button font-bold" onClick={handleViewMode}>
        Close
      </button>

      <div className="bg-blue-900 p-8 flex items-center gap-8 flex-wrap">
        <img
          src={occupant.primaryPhoto || NoPhoto}
          alt=""
          className="w-60 aspect-square object-cover object-top rounded-full"
        />
        <div className="mt-4 text-white font-bold uppercase text-lg">
          {occupant.firstName} {occupant.middleName} {occupant.lastName}
        </div>
      </div>

      <div className="p-8">
        {editMode && (
          <>
            <div className="mt-4">
              <input type="file" onChange={handleUpload} />
              <progress value={progress} max="100" />
            </div>
          </>
        )}
        <div className="mt-2">
          <button className="blue-button mb-4" onClick={handleEditMode}>
            {editMode ? "Cancel" : "Edit Photo"}
          </button>
        </div>

        <div className="links-container text-left">
          {links.map((link) => (
            <div
              className={
                activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
              }
              onClick={() => setActiveLink(link)}
            >
              {link}
            </div>
          ))}
        </div>

        <div className="bubble mt-4 p-4">
          {activeLink === "Information" && (
            <OccupantFormData occupantId={occupantId} />
          )}

          {activeLink === "Documents" && (
            <OccupantDocuments
              formData={formData}
              tenantId={tenantId}
              tenant={tenant}
              occupantId={occupantId}
            />
          )}
        </div>
      </div>
    </div>
  );
}
