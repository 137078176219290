import React, { useState } from "react";
import { setDocData } from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { v4 } from "uuid";
import { useSelector } from "react-redux";

export default function ScheduledMaintenance({ item, id }) {
  const currentUser = useSelector((state) => state.userObject);
  const [comments, setComments] = useState(item.comments || []);
  const [newComment, setNewComment] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [assignedId, setAssignedId] = useState(v4());

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewComment(value);
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    const comment = {
      createdBy: "Admin",
      message: newComment,
      createdAt: new Date().toLocaleString(),
    };
    setComments((prev) => [...prev, comment]);
    await setDocData("maintenanceItems", id, {
      comments: [...comments, comment],
    });
    setIsProcessing(false);
    toast.success("Comment added successfully");
    setAddMode(false);
  };

  const handleComplete = async () => {
    setIsProcessing(true);

    if (
      window.confirm("Are you sure you want to mark this item as completed?")
    ) {
      const completedObject = {
        ...item,
        maintenanceId: id,
        status: "Completed",
        comments,
        completedAt: new Date().toLocaleString(),
        completedBy: currentUser.uid,
        completedName: currentUser.displayName,
        completedEmail: currentUser.email,
      };
      await setDocData("maintenanceItems", id, {
        status: "Updated",
        lastMaintenanceSchedule: new Date().toLocaleString(),
        lastMaintenanceCost: item.budget,
        lastMaintenanceSupplier: item.supplier,
        nextSchedule: "Not Set",
        updatedBy: currentUser.uid,
        updatedName: currentUser.displayName,
        updatedEmail: currentUser.email,
        updatedAt: new Date().toLocaleString(),
        updateType: "Maintenance Completion",
      });

      await setDocData("completedMaintenance", assignedId, completedObject);
      setIsProcessing(false);
      toast.success("Maintenance item marked as completed");
    } else {
      return setIsProcessing(false);
    }
  };

  return (
    <div className="w-full max-w-[1080px]">
      <div className="flex items-center gap-4">
        <div className="">
          <div>Scheduled Date</div>
          <div>{item.nextSchedule}</div>
        </div>

        <div>
          <div>Supplier Assigned</div>
          <div>{item.supplier}</div>
        </div>

        <div>
          <div>Budget</div>
          <div>Php {item.budget}</div>
        </div>
      </div>

      <div className="mt-8 w-full">
        <div className="header">Comments</div>

        <div>
          <button className="blue-button" onClick={() => setAddMode(!addMode)}>
            Add a Comment
          </button>

          {addMode && (
            <div className="w-full">
              <textarea
                className="w-full p-2 mt-2 border border-gray-300 rounded-md"
                name="comment"
                placeholder="Add new comment"
                onChange={handleChange}
                rows={4}
                id=""
                required
              ></textarea>
              <div className="text-right">
                <button className="blue-button" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="mt-4">
          {comments.map((comment, index) => (
            <div className="comment mt-2">
              <div>{comment.createdBy}</div>
              <div>{comment.message}</div>
              <div>{comment.createdAt}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4">
        <button className="green-button" onClick={handleComplete}>
          Mark as Completed
        </button>
      </div>
    </div>
  );
}
