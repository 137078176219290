import React, { useState } from "react";
import { addNewDoc } from "../../../utils/firebase/Firebase.utils";
import InputBox from "../Components/InputBox";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function NewInventoryForm({ setAddMode }) {
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);

  const currentUser = useSelector((state) => state.userObject);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.uid,
      createdName: currentUser.displayName,
      createdEmail: currentUser.email,
      updateType: "new inventory item created",
    };

    await addNewDoc("inventory", docObject);
    toast.success("New Inventory Item Successfully Created");
    setAddMode(false);
    setIsProcessing(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="small-form relative pt-8">
      <button className="close-button" onClick={() => setAddMode(false)}>
        Close
      </button>
      <div className="header">Add New Inventory Item</div>

      <div className="mt-4">
        <form action="" className="space-y-2">
          <InputBox
            label="Item Name"
            name="itemName"
            placeholder={"Enter item name"}
            onChange={handleChange}
            type={"text"}
            required
            disabled={isProcessing}
          />

          <InputBox
            label="Description"
            name="description"
            placeholder={"Enter Description"}
            onChange={handleChange}
            type={"text"}
            required
            disabled={isProcessing}
          />

          <InputBox
            label="Location"
            name="location"
            placeholder={"Enter location of item"}
            onChange={handleChange}
            type={"text"}
            required
            disabled={isProcessing}
          />

          <InputBox
            label="Custodian"
            name="custodian"
            placeholder={"Enter name of custodian"}
            onChange={handleChange}
            type={"text"}
            required
            disabled={isProcessing}
          />

          <div className="">
            <button
              className="blue-button w-full p-4"
              onClick={handleSubmit}
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Add Inventory Item"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
