import React from "react";
import InputBox from "./InputBox";
import ButtonBlue from "./ButtonBlue";
import { useEffect, useState } from "react";
import {
  signInWithEmail,
  findUserDocbyId,
} from "../../utils/firebase/Firebase.utils";
import Logo from "../assets/talilogo.jpg";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadUser } from "../../store/userSlice";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import PageModal from "../Dashboard/Components/PageModal";
import PrivacyPage from "./PrivacyPage";

function LoginForm() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [viewMode, setViewMode] = React.useState(false);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const Navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const signIn = async () => {
      try {
        setIsLoading(true);
        const res = await signInWithEmail(formData.email, formData.password);
        if (res) {
          const { email, uid } = res.user;

          const { displayName, roles, department, linkedIdentifiers } =
            await findUserDocbyId(res.user.uid);

          dispatch(
            loadUser({
              email,
              displayName,
              uid,
              roles,
              department,
              linkedIdentifiers,
            })
          );
          Navigate("/dashboard?splash=true");
        }
        setIsLoading(false);
      } catch (error) {}
      setIsProcessing(false);
    };
    signIn();
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleViewPrivacyPolicy = () => {
    setViewMode(!viewMode);
  };
  return (
    <div className="bg-white rounded-xl shadow-lg p-4 w-[350px]">
      <div className="flex flex-col items-start justify-around gap-4">
        <div className="text-lg font-bold uppercase w-full text-center">
          <img src={Logo} alt="" className="w-20 h-20 m-auto" />
          <div className="text-gray-600 font-bold text-xs capitalize">
            Powered by
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713873813/elegho/Elegho-Logo_vah1pv.png"
              alt=""
              className="w-16 m-auto"
            />
          </div>
        </div>
        <div className="text-center w-full">
          Enter Your Log in Credentials Below
        </div>
        <div className="w-full ">
          <form className="w-full" onSubmit={handleSubmit}>
            <InputBox
              label="Email Address"
              type="email"
              placeholder="Enter your email address"
              onChange={handleChange}
              name={"email"}
            />
            <div className="relative mt-4">
              <InputBox
                label="Password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                onChange={handleChange}
                name={"password"}
              />
              <button
                type="button"
                className="absolute top-1/2 pt-1 right-2"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            <div className="mt-8">
              <ButtonBlue label={isProcessing ? "Logging in..." : "Login"} />
            </div>
            <div>
              <Link to="/resetpassword" className="text-blue-600">
                Forgot Password?
              </Link>
            </div>
            <div>
              Don't have an account?{" "}
              <Link to="/signup" className="text-blue-600">
                Sign up
              </Link>
            </div>

            <div
              className="underline text-gray-600 cursor-pointer"
              onClick={handleViewPrivacyPolicy}
            >
              Privacy Policy and Information
            </div>
          </form>

          {viewMode && (
            <PageModal>
              <PrivacyPage setViewMode={setViewMode} />
            </PageModal>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
