import React from "react";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../store/userSlice";
import { Link } from "react-router-dom";

import { IoMdMailUnread } from "react-icons/io";
import { IoIosSettings } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { RiLogoutBoxRFill } from "react-icons/ri";

function UserBar() {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(loadUser({}));
  };

  return (
    <div className="flex items-center justify-center gap-2 text-gray-500 ">
      <div className="hover:bg-gray-400 hover:text-white cursor-pointer p-2 rounded-lg">
        <Link to="/notifications">
          <IoMdMailUnread />
        </Link>
      </div>

      <div className="hover:bg-gray-400 hover:text-white cursor-pointer p-2 rounded-lg">
        <Link to="/settings">
          <IoIosSettings />
        </Link>
      </div>
      <div className="hover:bg-gray-400 hover:text-white cursor-pointer p-2 rounded-lg">
        <Link to="/profile">
          <FaUser />
        </Link>
      </div>
      <button
        className="hover:bg-gray-400 hover:text-white cursor-pointer p-2 rounded-lg"
        onClick={handleLogout}
      >
        <RiLogoutBoxRFill />
      </button>
    </div>
  );
}

export default UserBar;
