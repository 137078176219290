import React, { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import ConcernsCard from "./ConcernsCard";
import PageModal from "./Components/PageModal";
import ConcernsPageDetails from "./ConcernsPageDetails";

function JobOrders() {
  const [activeFilter, setActiveFilter] = React.useState("All");
  const [concerns, setConcerns] = React.useState([]);

  const [filteredJobOrders, setFilteredJobOrders] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedConcern, setSelectedConcern] = useState("");

  useEffect(() => {
    const fetchConcerns = async () => {
      await getAllDocsSnap("concerns", (data) => {
        // arrange data by createdAt
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setConcerns(data);
        setFilteredJobOrders(data);
        setIsLoading(false);
      });
    };
    fetchConcerns();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const statusLinks = ["Pending", "Scheduled", "Resolved"];

  const tableHeaders = [
    "Unit",
    "Description",
    "Photo",
    "Status",
    "Date",
    "Assigned To",
    "Date Assigned",
    "Priority",
    "Requested By",
    "Actions",
  ];

  const filterLinks = [
    "All",
    "Pending",
    "Processing",
    "Dispatched",
    "Resolved",
  ];

  const activeFilterStyle = "bg-green-500 text-white font-bold";

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const activeConcerns = concerns.filter((concern) => {
    if (activeFilter === "All") {
      return concern;
    }
    return concern.status.toLowerCase() === activeFilter.toLowerCase();
  });

  const filteredConcerns = activeConcerns.filter((concern) => {
    return (
      concern.identifier.toLowerCase().includes(searchTerm.toLowerCase()) ||
      concern.concern.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleViewMode = (selectedCard) => {
    setViewMode(!viewMode);
    setSelectedConcern(selectedCard);
  };

  return (
    <div className="text-left">
      <div className="font-bold text-blue-900 text-sm">Residents' Concerns</div>

      <div className="flex items-center justify-normal gap-4 mt-2">
        {filterLinks.map((link, index) => (
          <div
            key={index}
            className={`cursor-pointer text-sm rounded-full border-2 border-gray-300 px-2 py-1 min-w-20 text-center ${
              activeFilter === link ? "active-pill" : "inactive-pill "
            }
                `}
            onClick={() => setActiveFilter(link)}
          >
            {link}
          </div>
        ))}
      </div>

      <div className="flex items-center justify-between  p-2 rounded-lg border-2 border-gray-300 mt-4">
        <div>
          <input
            type="search"
            placeholder="Search by Address or Concern"
            className="border-2 border-gray-300 px-4 py-2 rounded-lg w-60"
            onChange={handleSearch}
          />
        </div>
      </div>

      <div className="mt-4 space-y-2">
        {filteredConcerns.map((concern, index) => (
          <div key={index} onClick={() => handleViewMode(concern)}>
            <ConcernsCard concern={concern} />
          </div>
        ))}
      </div>

      {viewMode && (
        <PageModal>
          <ConcernsPageDetails
            concern={selectedConcern}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}

export default JobOrders;
