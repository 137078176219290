import React from "react";

export default function PropertyOverviewReport({ data, setViewMode }) {
  return (
    <div className="page relative text-xs text-left text-gray-600">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>
      <div className="header">Property Overview Report</div>
      <div>Report not available - incomplete data</div>
    </div>
  );
}
