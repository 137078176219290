import React from "react";

function BillingCard({ billing }) {
  //date firebase timestamp

  return (
    <div className="bg-white rounded-xl p-2 shadow-sm hover:bg-blue-300 text-gray-600 text-sm mt-2">
      <div className="uppercase font-bold text-xs">{billing.identifier}</div>
      <div>Due Date: {billing.dueDate}</div>
      <div className="capitalize">Billing for: {billing.billingType}</div>
      <div className="capitalize">{billing.description}</div>
      <div className="capitalize">Status: {billing.status}</div>

      <div className="font-bold">Php {billing.amount}</div>
    </div>
  );
}

export default BillingCard;
