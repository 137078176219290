import React, { useEffect, useState } from "react";
import InputBox from "../Components/InputBox";
import SelectInput from "../Components/SelectInput";
import PageModal from "../Components/PageModal";
import Modal from "../Components/Modal";
import {
  addNewDoc,
  getAllDocsSnap,
  getDocDataSnap,
} from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import MaintenanceItemsCards from "./MaintenanceItemsCards";
import ItemDetails from "./ItemDetails";
import AddItemForm from "./AddItemForm";

export default function MaintenanceNewPage() {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = useState({});
  const [addMode, setAddMode] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    const fetchItems = async () => {
      await getAllDocsSnap("maintenanceItems", (data) => {
        setItems(data);
        setIsLoading(false);
      });
    };
    fetchItems();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const itemTypes = ["Equipment", "Vehicle", "Facility"];
  const itemTypesOptions = itemTypes.map((item) => {
    return {
      value: item,
      label: item,
    };
  });

  const handleAddMode = () => {
    setAddMode(!addMode);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectType = (selected) => {
    setFormData({
      type: selected.value,
    });
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.uid,
      createdName: currentUser.displayName,
      createdEmail: currentUser.email,
    };

    await addNewDoc("maintenanceItems", docObject);
    toast.success("Item Successfully Added");
    setIsProcessing(false);
    setAddMode(false);
    setFormData("");
  };

  const handleViewMode = (item) => {
    setViewMode(true);
    setSelectedItem(item);
  };

  console.log(formData);

  return (
    <div className="text-left text-gray-600">
      <div>
        <button className="blue-button" onClick={handleAddMode}>
          Create a maintenance item
        </button>

        {addMode && (
          // add Item Form
          <Modal>
            <AddItemForm setAddMode={setAddMode} />
          </Modal>
        )}

        <div className="mt-4">
          {items.map((item, index) => (
            <div
              className="mt-2"
              key={index}
              onClick={() => handleViewMode(item)}
            >
              <MaintenanceItemsCards item={item} />
            </div>
          ))}
        </div>
      </div>

      {viewMode && (
        <PageModal>
          <ItemDetails item={selectedItem} setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}
