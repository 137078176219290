import React, { useEffect } from "react";
import Modal from "../Dashboard/Components/Modal";
import IncidentForm from "./IncidentForm";
import PageModal from "../Dashboard/Components/PageModal";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import IncidentReportsCard from "./IncidentReportsCard";
import IncidentReportDetails from "./IncidentReportDetails";

export default function IncidentReports() {
  const [addMode, setAddMode] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [reports, setReports] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedReport, setSelectedReport] = React.useState({});
  const [viewMode, setViewMode] = React.useState(false);

  useEffect(() => {
    const fetchReports = async () => {
      await getAllDocsSnap("incidentReports", (data) => {
        setReports(data);
        setIsLoading(false);
      });
    };
    fetchReports();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleAddMode = () => {
    setAddMode(true);
  };

  const handleView = (report) => {
    setViewMode(true);
    setSelectedReport(report);
  };
  return (
    <div className="text-gray-600 text-xs text-left mt-8">
      <div className="header">Incident Reports</div>
      <button className="blue-button mt-2" onClick={handleAddMode}>
        Log an Incident
      </button>

      <div>
        {reports.map((report, index) => (
          <div key={index} className="mt-2" onClick={() => handleView(report)}>
            <IncidentReportsCard data={report} />
          </div>
        ))}
      </div>

      {addMode && (
        <PageModal>
          <IncidentForm setAddMode={setAddMode} />
        </PageModal>
      )}

      {viewMode && (
        <PageModal>
          <IncidentReportDetails
            data={selectedReport}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}
