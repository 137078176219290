import React from "react";

export default function WorkPermitCards({ application }) {
  const {
    identifier,
    name,
    id,
    status,
    createdAt,
    approvedAt,
    description,
    remarks,
    createdBy,
    workPermitType,
  } = application;

  return (
    <div className="relative max-w-[1440px]">
      <div className="card cursor-pointer  p-2 hover:bg-gray-200 flex lg:items-center gap-2 text-xs text-gray-600 lg:flex-row flex-col items-start ">
        <div className="lg:w-60 flex items-center gap-2 lg:block">
          <div className="text-gray-600 rounded-full bg-gray-100 text-[8px] px-1 w-16">
            Lot Number
          </div>
          <div className="font-bold text-blue-900">{identifier}</div>
        </div>
        <div className="lg:w-36 flex items-center gap-2 lg:block">
          <div className="text-gray-600 rounded-full bg-gray-100 text-[8px] px-1  w-16">
            Type of Work
          </div>
          <div className="capitalize font-bold">{workPermitType}</div>
        </div>
        <div className="lg:w-36 flex items-center gap-2 lg:block">
          <div className="text-gray-600 rounded-full bg-gray-100 text-[8px] px-1  w-16">
            Applicant
          </div>
          <div className="capitalize font-bold">{name}</div>
        </div>
        <div className="lg:w-36 flex items-center gap-2 lg:block">
          <div className="text-gray-600 rounded-full bg-gray-100 text-[8px] px-1  w-16">
            Date Applied
          </div>
          <div className="capitalize font-bold">{createdAt}</div>
        </div>
        <div className="w-36">
          <div className="text-gray-600 rounded-full bg-gray-100 text-[8px] px-1  w-24">
            No. of days resolved
          </div>
          <div className=" font-bold">
            {status !== "Approved"
              ? "0"
              : Math.ceil(
                  (new Date(approvedAt) - new Date(createdAt)) /
                    (1000 * 60 * 60 * 24)
                )}
          </div>
        </div>
        <div className=" absolute top-2 right-2 lg:flex">
          <div className={status.toLowerCase()}>{status}</div>
        </div>
      </div>
    </div>
  );
}
