import React from "react";
import { motion } from "framer-motion";

function Modal({ children }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white p-2 rounded-xl "
      >
        {children}
      </motion.div>
    </div>
  );
}

export default Modal;
