import React, { useEffect } from "react";
import Modal from "../Components/Modal";
import AddVehicleForm from "./AddVehicleForm";
import { queryAllDocsByFieldSnap } from "../../../utils/firebase/Firebase.utils";
import ResidentVehiclesCard from "./ResidentVehiclesCard";
import { useParams } from "react-router-dom";
import PageModal from "../Components/PageModal";
import VehicleDetailsPage from "./VehicleDetailsPage";

export default function VehiclesInformationPage() {
  const [addMode, setAddMode] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [vehicles, setVehicles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { id } = useParams();
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedDoc, setSelectedDoc] = React.useState({});
  const [refresh, setRefresh] = React.useState(false);

  useEffect(() => {
    const fetchAllVehicles = async () => {
      await queryAllDocsByFieldSnap(
        "vehicles",
        "residentId",
        "==",
        id,
        (data) => {
          //arrange by createdAt
          data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });

          setVehicles(data);
          setIsLoading(false);
        }
      );
    };
    fetchAllVehicles();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleViewMode = (doc) => {
    setSelectedDoc(doc);
    setViewMode(true);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <div>
      <div>
        <button className="blue-button" onClick={handleAddMode}>
          Register a vehicle
        </button>
      </div>

      <div className="mt-4 space-y-2">
        {vehicles.map((vehicle, index) => (
          <div key={index} onClick={() => handleViewMode(vehicle)}>
            <ResidentVehiclesCard vehicle={vehicle} />
          </div>
        ))}
      </div>

      {addMode && (
        <Modal>
          <AddVehicleForm handleAddMode={handleAddMode} />
        </Modal>
      )}

      {viewMode && (
        <PageModal>
          <VehicleDetailsPage
            vehicle={selectedDoc}
            setViewMode={setViewMode}
            handleRefresh={handleRefresh}
          />
        </PageModal>
      )}
    </div>
  );
}
