import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getAllDocsSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import FacilityRequestCard from "./Facilities/FacilityRequestCard";
import PageModal from "./Components/PageModal";
import FacilityReqPage from "./Facilities/FacilityReqPage";

function Facilities() {
  const [activeLink, setActiveLink] = React.useState("");
  const [filteredRequests, setFilteredRequests] = React.useState([]);
  const [activeFilter, setActiveFilter] = React.useState("All");
  const [searchParams, setSearchParams] = useSearchParams();
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const facility = searchParams.get("facility");
  const filter = searchParams.get("filter");

  const facilitiesLinks = [
    { name: "Pickle Ball Court", value: "pickleball" },
    { name: "BasketBall Court", value: "basketball" },
  ];

  useEffect(() => {
    setActiveLink(facilitiesLinks[0].value);
  }, []);

  useEffect(() => {
    const fetchRequests = async () => {
      await getAllDocsSnap("facilityRequests", (data) => {
        setRequests(data);
        setIsLoading(false);
      });
    };
    fetchRequests();
  }, []);

  useEffect(() => {
    setSearchParams({ facility: activeLink, filter: activeFilter });
  }, [activeLink, activeFilter]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const filters = ["Pending", "Approved", "Rejected", "All"];

  const currentItems = requests.filter((item) => item.type === activeLink);
  const filteredItems = currentItems.filter(
    (item) =>
      item.status.toLowerCase().includes(activeFilter.toLowerCase()) ||
      item.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.user.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleViewMode = (selected) => {
    setSelectedRequest(selected);
    setViewMode(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="p-2 text-blue-900 space-y-4 text-xs">
      <div className="flex items-center justify-between">
        <div className="font-bold text-xl">Facilities</div>
        <div className="flex items-center space-x-2">
          {/* <button className="blue-button">Edit</button>
          <button className="blue-button">Print</button> */}
        </div>
      </div>
      <div className="border-2 border-gray-300 p-2 flex items-center justify-between">
        <div className="links-container">
          {facilitiesLinks.map((facility, index) => (
            <div
              key={index}
              className={`
                ${
                  activeLink === facility.value
                    ? "activeLinkStyle"
                    : "inactiveLinkStyle"
                }
                `}
              onClick={() => setActiveLink(facility.value)}
            >
              {facility.name}
            </div>
          ))}
        </div>
        <div className="space-x-4 ">
          <input
            type="text"
            placeholder="Search by Name, Request No."
            className="p-2 py-1 w-96 rounded-lg border-2 border-gray-300"
            onChange={handleSearch}
          />
          {/* <button>Filter</button> */}
        </div>
      </div>

      {/* map cards here */}
      {filteredItems.map((item, index) => (
        <div key={index} onClick={() => handleViewMode(item)}>
          <FacilityRequestCard data={item} />
        </div>
      ))}

      {viewMode && (
        <PageModal>
          <FacilityReqPage data={selectedRequest} setViewMOde={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}

export default Facilities;
