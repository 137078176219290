import React, { useEffect, useState } from "react";
import InputBox from "../Components/InputBox";
import SelectInput from "../Components/SelectInput";
import {
  setDocData,
  getDocDataSnap,
} from "../../../utils/firebase/Firebase.utils";
import Label from "../Components/Label";
import { storage } from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import PersonViewer from "./PersonViewer";
import Modal from "../Components/Modal";
import toast from "react-hot-toast";

function OccupantInfoForm({ resident, viewMode, setViewMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [addOccupant, setAddOccupant] = useState(false);
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [residentData, setResidentData] = useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [occupants, setOccupants] = useState([]);
  const [assignedId, setAssignedId] = useState(v4());
  const [personViewMode, setPersonViewMode] = useState(false);
  const [personData, setPersonData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentUpload, setCurrentUpload] = useState(null);
  const [editOccupant, setEditOccupant] = useState(false);

  useEffect(() => {
    const fetchResidentData = async () => {
      getDocDataSnap("residents", resident, (data) => {
        setResidentData(data);
        setOccupants(data.occupants || []);
        setIsLoading(false);
      });
    };
    fetchResidentData();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  const occupantTypes = [
    "Owner",
    "Tenant",
    "Dependent",
    "Employee",
    "Helper",
    "Driver",
    "Other",
  ];

  const occupantTypesOptions = occupantTypes.map((type) => {
    return { value: type, label: type };
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelect = (e, name) => {
    setFormData((prev) => ({ ...prev, [name]: e.value }));
  };

  const handleAddOccupant = () => {
    setAddOccupant(!addOccupant);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    //check if all fields are filled
    if (
      !formData.type ||
      !formData.name ||
      !formData.bday ||
      !formData.address ||
      !formData.phone
    ) {
      toast.error("Please fill up all fields");
      setIsProcessing(false);
      return;
    }

    // if edit mode
    if (editOccupant) {
      const newOccupants = residentData.occupants.map((occupant) => {
        if (occupant.id === formData.id) {
          return formData;
        }
        return occupant;
      });
      await setDocData("residents", resident, { occupants: newOccupants });
      setFormData({});
      setAddOccupant(false);
      setIsProcessing(false);
      setEditOccupant(false);
      return;
    }

    const docObject = {
      ...formData,
      id: assignedId,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.uid,
    };

    // save to firebase

    if (!residentData.occupants) {
      await setDocData("residents", resident, { occupants: [docObject] });
      setFormData({});
      setAddOccupant(false);
      setIsProcessing(false);
      return;
    }

    await setDocData("residents", resident, {
      occupants: [...residentData.occupants, docObject],
    });
    setFormData({});
    setAddOccupant(false);
    setIsProcessing(false);
  };

  const handleUpload = async (e, name) => {
    const file = e.target.files[0];
    const storageRef = ref(
      storage,
      `occupants/${resident}/${assignedId}/${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    setCurrentUpload(name);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
        }
      },
      (error) => {
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData((prev) => ({ ...prev, [name]: downloadURL }));
        });
      }
    );
  };

  const handlePersonViewer = (person) => {
    setPersonData(person);
    setPersonViewMode(true);
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this occupant?")) {
      const newOccupants = residentData.occupants.filter(
        (occupant) => occupant.id !== id
      );
      await setDocData("residents", resident, { occupants: newOccupants });
      toast.success("Occupant deleted successfully");
    }
  };

  const handleEditPerson = async (id) => {
    setFormData(residentData.occupants.find((occupant) => occupant.id === id));
    setEditOccupant(true);
    setAssignedId(id);
    setAddOccupant(true);
  };

  return (
    <div className="text-left">
      <div>
        <div className="flex gap-2">
          <button className="blue-button" onClick={handleAddOccupant}>
            {addOccupant ? "Cancel" : "Add Occupant"}
          </button>
          {addOccupant && (
            <button className="blue-button" onClick={handleSave}>
              {isProcessing ? "Saving..." : "Save"}
            </button>
          )}
        </div>

        {/* add occupant form */}
        <div className="mt-4">
          {addOccupant && (
            <div className="flex items-center gap-2 flex-wrap border border-gray-100 rounded-lg">
              <SelectInput
                className="w-60"
                name="type"
                label={"Type"}
                required
                onChange={(e) => handleSelect(e, "type")}
                options={occupantTypesOptions}
                value={occupantTypesOptions.find(
                  (option) => option.value === formData.type
                )}
              />

              <InputBox
                name="name"
                placeholder={"Name"}
                label={"Name"}
                type={"text"}
                required
                onChange={handleChange}
                value={formData.name}
              />

              <InputBox
                name="bday"
                placeholder={"Birthday"}
                label={"Birthday"}
                required
                type={"date"}
                onChange={handleChange}
                value={formData.bday}
              />

              <InputBox
                name="address"
                placeholder={"Address other than the lot owner's"}
                label={"Address"}
                required
                type={"text"}
                onChange={handleChange}
                value={formData.address}
              />

              <InputBox
                name="phone"
                placeholder={"Contact Number"}
                label={"Contact Number"}
                required
                onChange={handleChange}
                value={formData.phone}
              />

              <Label label="Upload ID">
                <input
                  type="file"
                  name="identification"
                  onChange={(e) => handleUpload(e, "identification")}
                  accept="image/*"
                />
                <div>
                  {currentUpload === "identification" && (
                    <progress value={progress} max="100" />
                  )}
                </div>
              </Label>

              <Label label="Upload Photo">
                <input
                  type="file"
                  name="photo"
                  onChange={(e) => handleUpload(e, "photo")}
                  accept="image/*"
                />
                <div>
                  {currentUpload === "photo" && (
                    <progress value={progress} max="100" />
                  )}
                </div>
              </Label>
            </div>
          )}
        </div>

        {/* mapping of all the occupants */}
        {!addOccupant && (
          <div className="mt-4">
            <div className="flex items-center justify-normal gap-2 mb-4">
              <div className="text-blue-900 text-left font-bold ">
                Occupants
              </div>
              <button className="underline text-xs" onClick={handleEditMode}>
                {editMode ? "Save" : "Edit"}
              </button>
            </div>
            <div className="flex flex-col gap-2">
              {occupants.map((occupant, index) => (
                <div
                  key={index}
                  className="flex gap-2 [&>*]:w-60 text-xs flex-wrap border border-gray-100 p-2 rounded-xl"
                >
                  <div className="flex items-center gap-2">
                    <div className="text-blue-900 font-bold">
                      {occupant.type}
                    </div>
                    <div
                      className="underline cursor-pointer"
                      onClick={() => handlePersonViewer(occupant)}
                    >
                      {occupant.name}
                    </div>
                  </div>
                  <div>{occupant.bday}</div>
                  <div>Address: {occupant.address}</div>
                  <div>Contact Number: {occupant.phone}</div>
                  {editMode && (
                    <div className="flex items-center justify-normal gap-2">
                      <button
                        className="underline"
                        onClick={() => handleEditPerson(occupant.id)}
                      >
                        Edit
                      </button>
                      <button
                        className="underline"
                        onClick={() => handleDelete(occupant.id)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {personViewMode && (
        <Modal>
          <PersonViewer
            person={personData}
            setPersonViewMode={setPersonViewMode}
          />
        </Modal>
      )}
    </div>
  );
}

export default OccupantInfoForm;
