import React, { useEffect } from "react";
import {
  getDocDataSnap,
  setDocData,
  storage,
} from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";

export default function OccupantDocuments({
  formData,
  tenantId,
  tenant,
  occupantId,
}) {
  const [progress, setProgress] = React.useState(0);
  const [occupant, setOccupant] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [activeUpload, setActiveUpload] = React.useState("");
  const [editMode, setEditMode] = React.useState(false);

  const requiredDocuments = ["Valid ID", "Secondary ID"];

  

  const handleUpload = (e, doc) => {
    e.preventDefault();
    setActiveUpload(doc);
    const file = e.target.files[0];
    const storageRef = ref(storage, `tenants/occupants/${tenantId}/${doc}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setDocData("tenants", tenantId, {
            occupantsName: tenant.occupantsName.map((occupant) => {
              if (occupant.id === occupantId) {
                return {
                  ...occupant,
                  documents: {
                    ...occupant.documents,
                    [doc]: downloadURL,
                  },
                };
              }
              return occupant;
            }),
          });

          toast.success(`${doc} Uploaded Successfully`);
          setProgress(0);
        });
      }
    );
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <div>
      <div className="flex items-center gap-2">
        <div className="header">Occupant Documents</div>

        <div>
          <button
            className={editMode ? "underline" : "blue-button"}
            onClick={handleEditMode}
          >
            {editMode ? "Cancel" : "Edit Documents"}
          </button>
        </div>
      </div>

      <div className="flex flex-col items-start gap-4 mt-4">
        {requiredDocuments.map((doc, index) => (
          <div
            key={index}
            className="flex lg:flex-row lg:items-center gap-4 flex-col "
          >
            <div className="w-60">{doc}</div>
            {editMode && (
              <>
                <input type="file" onChange={(e) => handleUpload(e, doc)} />
                <progress value="0" max="100" />
              </>
            )}
            {formData.documents && formData.documents[doc] ? (
              <a
                href={formData.documents[doc]}
                target="_blank"
                rel="noreferrer"
              >
                View
              </a>
            ) : (
              <div>No File Uploaded</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
