import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import TenantDetails from "./TenantDetails";
import Modal from "../Components/Modal";
import TenantCard from "./TenantCard";
import { Link } from "react-router-dom";

function AllTenants({ searchTerm }) {
  const [tenants, setTenants] = React.useState([]);
  const [tenant, setTenant] = React.useState({});
  const [viewMode, setViewMode] = React.useState(false);
  const [residents, setResidents] = React.useState([]);

  const tableHeaders = [
    "Lot Owner",
    "Phase Block and Lot Number",
    "Tenant Name",
    "Occupants",
    "Address",
    "Lease Start",
    "Lease End",
    "Documents",
  ];

  useEffect(() => {
    const fetchResidents = async () => {
      getAllDocsSnap("residents", (residents) => {
        setResidents(residents);
      });
    };

    fetchResidents();
  }, []);

  useEffect(() => {
    const fetchTenants = async () => {
      getAllDocsSnap("tenants", (tenants) => {
        const mappedTenants = tenants.map((tenant) => {
          const unitOwner = residents.find(
            (resident) => resident.identifier === tenant.lot
          );
          return {
            ...tenant,
            unitOwner: unitOwner?.owner1 || "N/A",
            tenantName: `${tenant.firstName} ${tenant.lastName}`,
            documents: "View",
          };
        });
        setTenants(mappedTenants);
      });
    };

    fetchTenants();
  }, [residents]);

  const handleViewMode = (tenant) => {
    setTenant(tenant);
    setViewMode(true);
  };

  const filteredTenants = tenants.filter(
    (tenant) =>
      tenant.tenantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tenant.unitOwner.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className=" mt-4 text-xs">
      <div className="font-bold text-sm text-blue-900 text-left mb-4 uppercase">
        All Lease Tenants
      </div>

      <div className="space-y-2">
        {filteredTenants.map((tenant, index) => (
          <div key={index} className="" onClick={() => handleViewMode(tenant)}>
            <Link to={`/tenant/${tenant.id}?detail=Basic+Information`}>
              <TenantCard tenant={tenant} />
            </Link>
          </div>
        ))}
      </div>

      {/* {viewMode && (
        <Modal>
          <div>
            <div
              className="text-right underline cursor-pointer"
              onClick={() => setViewMode(false)}
            >
              Close
            </div>
            <div className="lg:min-w-[800px] min-h-[500px]">
              <TenantDetails
                viewMode={viewMode}
                setViewMode={setViewMode}
                tenant={tenant}
              />
            </div>
          </div>
        </Modal>
      )} */}
    </div>
  );
}

export default AllTenants;
