import React from "react";
import UserScreenWindow from "./UserScreenWindow";

import { IoWarningOutline } from "react-icons/io5";
import { BsEnvelopeExclamation } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function OtherServicesUser() {
  const navigate = useNavigate();

  const requestTypes = [];

  return (
    <UserScreenWindow label="Other Service Providers">
      <div className="text-neutral-700">
        <div className="text-center text-xs font-bold mt-4 text-blue-900">
          OTHER CONTACTS
        </div>
        <div className="flex items-center justify-start gap-2 flex-wrap p-2 rounded-xl ">
          {requestTypes.map((request, index) => (
            <div
              className="text-xs flex flex-col items-center justify-center w-20 h-20 bg-green-100 rounded-lg hover:bg-green-200 cursor-pointer"
              key={index}
              onClick={() => navigate(`${request.link}`)}
            >
              <div className="text-4xl">{request.icon}</div>
              <div className="text-xs font-semibold">{request.label}</div>
            </div>
          ))}

          {requestTypes.length < 1 && (
            <div className="text-center text-xs text-neutral-500 mt-4">
              You are all caught up! No other services request for now.
            </div>
          )}
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default OtherServicesUser;
