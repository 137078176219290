import React from "react";
import InputBox from "../Components/InputBox";
import SelectInput from "../Components/SelectInput";
import { useEffect } from "react";
import toast from "react-hot-toast";
import {
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import { useParams } from "react-router-dom";
import Modal from "../Components/Modal";
import { useSelector } from "react-redux";

export default function ResidentInformationPage({}) {
  const { id } = useParams();
  const [formData, setFormData] = React.useState({});
  const [editMode, setEditMode] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [resident, setResident] = React.useState({});

  const currentUser = useSelector((state) => state.userObject);

  const allowableAccess = ["admin", "maker", "approver"];
  const userRoles = currentUser.roles;

  useEffect(() => {
    const fetchResidents = async () => {
      await getDocDataSnap("residents", id, (data) => {
        setResident(data);
        setFormData(data);
        setIsLoading(false);
      });
    };
    fetchResidents();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const propertyTypes = [
    {
      label: "House and Lot",
      value: "House and Lot",
    },
    {
      label: "Lot Only",
      value: "Lot Only",
    },
  ];

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditMode = () => {
    setEditMode(!editMode);

    if (editMode) {
      setFormData(resident);
      toast.error("Changes have not been saved");
    }
  };

  const handleSave = async (e) => {
    if (window.confirm("Are you sure you want to save the changes?")) {
      e.preventDefault();
      setIsProcessing(true);
      const docObject = {
        ...formData,
        identifier: `Phase ${formData.phase}- Block ${formData.block}- Lot ${formData.lot}`,
      };
      await setDocData("residents", id, docObject);
      toast.success("Changes saved successfully");
      setEditMode(false);
      setIsProcessing(false);
    }
  };

  return (
    <div className="bubble p-4 lg:p-8 shadow-md">
      {allowableAccess.some((role) => userRoles.includes(role)) && (
        <div className="mt-4">
          <button
            className={editMode ? "underline" : "blue-button"}
            onClick={handleEditMode}
          >
            {editMode ? "Cancel" : "Edit"}
          </button>
        </div>
      )}

      <div className="header mt-4">{resident.identifier}</div>

      {/* actual resident information form */}
      <form action="" className="mt-4" onSubmit={handleSave}>
        {/* actual form */}
        <div className="flex items-center gap-4 flex-wrap mt-4">
          <div className="w-[500px]">
            <div>Basic Information</div>
            <div>
              <InputBox
                label="Owner 1"
                value={formData.owner1}
                onChange={handleChange}
                placeholder="Owner 1"
                name="owner1"
                disabled={!editMode}
              />
              <InputBox
                label="Owner 2"
                value={formData.owner2}
                onChange={handleChange}
                placeholder="Owner 2"
                name="owner2"
                disabled={!editMode}
              />
              <InputBox
                label="Address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Address"
                name="address"
                disabled={!editMode}
              />
              <InputBox
                label="Phone Number"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="Phone Number"
                name="phoneNumber"
                disabled={!editMode}
              />
            </div>
          </div>
          <div className="w-[500px]">
            <div>Lot Information</div>
            <div>
              <InputBox
                label="Phase Number"
                value={formData.phase}
                onChange={handleChange}
                placeholder="Phase Number"
                name="phase"
                disabled={!editMode}
              />
              <InputBox
                label="Block Number"
                value={formData.block}
                onChange={handleChange}
                placeholder="Block Number"
                name="block"
                disabled={!editMode}
              />
              <InputBox
                label="Lot Number"
                value={formData.lot}
                onChange={handleChange}
                placeholder="Lot Number"
                name="lot"
                disabled={!editMode}
              />
              <SelectInput
                label={"Property Type"}
                options={propertyTypes}
                value={propertyTypes.find(
                  (type) => type.value === formData.unitType
                )}
                disabled={!editMode}
              />
            </div>
          </div>
        </div>

        {/* save button visible only when in edit mode */}
        {editMode && (
          <div className="mt-4">
            <button className="blue-button w-full lg:w-fit" type="submit">
              {isProcessing ? "Saving..." : "Save"}
            </button>
          </div>
        )}
      </form>

      {/* modal for processing saving of changes */}
      {isProcessing && (
        <Modal>
          <div className="header">Saving changes...</div>
        </Modal>
      )}
    </div>
  );
}
