import React from "react";
import { FaHouse } from "react-icons/fa6";
import { FaCalendarAlt } from "react-icons/fa";

export default function TenantCard({ tenant }) {
  return (
    <div className="bg-white text-gray-600 p-2 flex lg:flex-row flex-col lg:items-center items-start justify-normal gap-2 flex-wrap hover:border hover:bg-gray-200 cursor-pointer rounded-md shadow-sm">
      <div className="flex items-start justify-start gap-2">
        <FaHouse className="text-xl text-green-600" />
        <div className="w-80 text-left font-bold">{tenant.lot}</div>
      </div>

      <div className="flex flex-col items-start justify-start pl-6 lg:pl-0">
        <div className=" w-96 text-left"> {tenant.unitOwner}</div>
        <div className="text-xs text-gray-400">Property Owner</div>
      </div>

      <div className="flex flex-col items-start justify-start pl-6 lg:pl-0">
        <div className="flex items-center gap-2 w-72  ">
          <div>{tenant.firstName}</div>
          <div>{tenant.lastName}</div>
        </div>
        <div className="text-xs text-gray-400">Tenant Name</div>
      </div>

      <div className="flex flex-col items-start justify-start pl-6 lg:pl-0">
        <div className="w-80 flex items-center justify-start gap-4">
          <FaCalendarAlt className="text-xl text-green-600" />
          {tenant.leaseStart} to {tenant.leaseEnd}
        </div>
      </div>
    </div>
  );
}
