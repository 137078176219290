import React from "react";
import InputBox from "../Components/InputBox";
import { addNewDoc } from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function InventoryFormAdd({ id, setAddMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const data = {
      ...formData,
      transactionType: "addition",
      itemId: id,
      user: currentUser.displayName,
      userId: currentUser.uid,
      createdAt: new Date().toLocaleString(),
      userEmail: currentUser.email,
      updateType: "addition of inventory",
    };
    await addNewDoc("inventoryTransactions", data);
    toast.success("Transaction recorded successfully");
    setFormData({});
    setAddMode(false);
    setIsProcessing(false);
  };

  return (
    <div>
      <div>
        <form action="" className="space-y-4" onSubmit={handleSubmit}>
          <InputBox
            label="quantity"
            type="text"
            name="quantity"
            placeholder={"Enter quantity amount"}
            onChange={handleChange}
            disabled={isProcessing}
            required
          />
          <InputBox
            label="Date of purchase:"
            type="date"
            name="date"
            placeholder={"Enter name issued to"}
            onChange={handleChange}
            disabled={isProcessing}
            required
          />
          <InputBox
            label="Average cost per unit"
            type="number"
            name="cost"
            placeholder={"Enter ave. cost per unit"}
            onChange={handleChange}
            disabled={isProcessing}
            required
          />
          <InputBox
            label="Remarks"
            type="text"
            name="remarks"
            placeholder={"Enter remarks"}
            onChange={handleChange}
            disabled={isProcessing}
            required
          />

          <div>
            <button
              className="blue-button"
              type="submit"
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
