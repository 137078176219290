import React from "react";

export default function NotificationWeb() {
  return (
    <div className="text-gray-600 text-xs text-left">
      <div className="header">Your Notifications</div>
      <div>You have no new notifications</div>
    </div>
  );
}
