import React from "react";

export default function FacilityReqPage({ data, setViewMOde }) {
  return (
    <div className="page text-gray-600 text-xs relative text-left">
      <button className="close-button" onClick={() => setViewMOde(false)}>
        Close
      </button>
      <div className="min-w-80">
        <div className="header">Facility Request Details</div>
      </div>
    </div>
  );
}
