import React from "react";
import InputBox from "../Components/InputBox";
import { addNewDoc } from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function AddVehicleForm({ handleAddMode }) {
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      status: "documents pending",
      docType: "residentVehicle",
      residentId: id,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.uid,
      name: currentUser.displayName,
    };
    const res = await addNewDoc("vehicles", docObject);
    console.log(res);
    setIsProcessing(false);
    toast.success("Vehicle registered successfully");
    handleAddMode();
  };

  return (
    <div className="small-form pt-8 relative">
      <button className="close-button" onClick={handleAddMode}>
        Cancel
      </button>
      <div className="header">Register Vehicle</div>

      <form onSubmit={handleSubmit} className="mt-4 space-y-2">
        <InputBox
          label="Plate Number"
          name="plateNumber"
          type="text"
          placeholder="Enter Plate Number"
          onChange={handleChange}
          value={formData.plateNumber}
          required
          disabled={isProcessing}
        />
        <InputBox
          label="Make or Model"
          name="model"
          type="text"
          placeholder="Enter Make or Model"
          onChange={handleChange}
          value={formData.model}
          required
          disabled={isProcessing}
        />

        <InputBox
          label="Name Registered"
          name="nameRegistered"
          type="text"
          placeholder="Enter Name Registered"
          onChange={handleChange}
          value={formData.nameRegistered}
          required
          disabled={isProcessing}
        />

        <div className="">
          <button className="blue-button w-full mt-8" disabled={isProcessing}>
            {isProcessing ? "Submitting.." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}
