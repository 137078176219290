import React from "react";
import EmptyHere from "../../assets/emptyData.png";

export default function EmptyPage({ children }) {
  return (
    <div>
      {children}
      <img src={EmptyHere} alt="Empty Here" className="w-96 mx-auto" />
    </div>
  );
}
