import React from "react";

export default function TenantOccupantCard({ occupant }) {
  return (
    <div className="bubble cursor-pointer hover:bg-gray-200 p-2">
      <div className="font-bold">
        {occupant.firstName} {occupant.middleName} {occupant.lastName}
      </div>
      <div>{occupant.relationship}</div>
    </div>
  );
}
