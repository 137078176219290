import React, { useEffect } from "react";
import { createNewDocument } from "../../../utils/firebase/Firebase.utils";
import InputBox from "../Components/InputBox";
import {
  getAllDocs,
  getDocDataSnap,
  setDocData,
  deleteDocById,
} from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import SelectInput from "../Components/SelectInput";

function AddTenantForm({ setAddMode, addMode, viewMode, setViewMode, tenant }) {
  const [formData, setFormData] = React.useState(tenant ? tenant : {});
  const [isProcesssing, setIsProcessing] = React.useState(false);
  const [residents, setResidents] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredResidents, setFilteredResidents] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  useEffect(() => {
    if (viewMode) {
      setDisabled(true);
    }

    if (addMode) {
      setDisabled(false);
    }
  }, []);

  useEffect(() => {
    const fetchResidents = async () => {
      const residents = await getAllDocs("residents");

      const unitNumbers = residents.map((resident) => {
        return {
          identifier: resident.identifier,
          value: resident.identifier,
          label: resident.owner1 + " - " + resident.identifier,
        };
      });

      //sort by identifier name
      unitNumbers.sort((a, b) => {
        if (a.identifier < b.identifier) {
          return -1;
        }
        if (a.identifier > b.identifier) {
          return 1;
        }
        return 0;
      });

      setResidents(unitNumbers);
      setIsLoading(false);
    };

    fetchResidents();
  }, []);

  useEffect(() => {
    setFilteredResidents(
      residents.filter(
        (resident) =>
          resident.label?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          resident.identifier?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  if (isLoading) {
    return <div>Loading</div>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const res = await createNewDocument("tenants", formData);
    if (res) {
      setIsProcessing(false);
      setAddMode(false);
      setFormData({});
    }
  };

  const options = [
    {
      label: "Select Unit Number",
      value: "",
    },
    ...residents,
  ];

  const leaseTypeOptions = [
    { label: "Land Lease", value: "Land Lease" },
    { label: "House Lease", value: "House Lease" },
  ];

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({ ...prev, lot: value }));
    setSearchTerm(value);
  };

  const handleResidentSelect = (e, resident) => {
    setFormData((prev) => ({ ...prev, lot: resident.value }));
    setSearchTerm("");
  };

  const handleEdit = () => {
    setEditMode(!editMode);
    setDisabled(!disabled);
  };

  const handleSaveEdits = async () => {
    setIsProcessing(true);
    const res = await setDocData("tenants", tenant.id, formData);
    if (res) {
      setIsProcessing(false);
      setEditMode(false);
      setDisabled(true);
      setViewMode(false);
    }
    toast.success("Tenant Details Updated Successfully");
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this tenant?")) {
      setIsProcessing(true);
      const res = await deleteDocById("tenants", tenant.id);
      if (res) {
        setIsProcessing(false);
        setViewMode(false);
        toast.success("Tenant Deleted Successfully");
      }
    } else {
      toast.error("Deletion Cancelled");
      return;
    }
  };

  const handleSelectLeaseType = (e) => {
    setFormData((prev) => ({ ...prev, leaseType: e.value }));
  };

  return (
    <div className="relative">
      <div className="text-left uppercase text-blue-900 font-bold w-fit">
        {addMode ? "Add Tenant" : "Tenant Details"}
      </div>

      {viewMode && (
        <div className="flex items-center justify-normal gap-4 underline text-sm">
          <button onClick={handleEdit}>
            {editMode ? "Cancel Edit" : "Edit"}
          </button>
          <button onClick={handleDelete}>Delete</button>
        </div>
      )}

      <form onSubmit={handleSubmit} className="">
        <div className="flex items-start justify-start gap-2 mt-4">
          <div className="w-1/2 h-full text-left flex flex-col gap-2">
            <div className="text-gray-600">Basic Information</div>
            <InputBox
              label={"First Name"}
              type={"text"}
              placeholder={"First Name"}
              name="firstName"
              required
              onChange={handleChange}
              value={formData.firstName}
              disabled={disabled}
            />
            <InputBox
              label={"Last Name"}
              type={"text"}
              placeholder={"Last Name"}
              name="lastName"
              required
              onChange={handleChange}
              value={formData.lastName}
              disabled={disabled}
            />
            <InputBox
              label={"Address"}
              type={"text"}
              placeholder={"Address"}
              name="address"
              required
              onChange={handleChange}
              value={formData.address}
              disabled={disabled}
            />
            <InputBox
              label={"Email"}
              type={"email"}
              placeholder={"Email"}
              name="email"
              required
              onChange={handleChange}
              value={formData.email}
              disabled={disabled}
            />
            <InputBox
              label={"Phone Number"}
              type={"tel"}
              placeholder={"Phone Number"}
              name="phoneNumber"
              required
              onChange={handleChange}
              value={formData.phoneNumber}
              disabled={disabled}
            />
          </div>

          <div className="w-1/2 h-full text-left flex flex-col gap-2">
            <div className="text-gray-600 ">Rent Information</div>

            <div className="relative">
              <InputBox
                name="lot"
                placeholder={"Lot Number"}
                label={"Lot Number"}
                type={"text"}
                required
                onChange={handleSearchChange}
                value={formData.lot}
                disabled={disabled}
              />

              {searchTerm && filteredResidents.length > 0 && (
                <ul className="bg-white p-2 rounded-xl border border-gray-200 shadow-lg absolute">
                  {filteredResidents.map((resident, index) => (
                    <li
                      key={index}
                      onClick={(e) => handleResidentSelect(e, resident)}
                      className="cursor-pointer hover:bg-gray-200"
                    >
                      {resident.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <InputBox
              label={"Lease Start"}
              type={"date"}
              placeholder={"Lease Start"}
              name="leaseStart"
              required
              onChange={handleChange}
              value={formData.leaseStart}
              disabled={disabled}
            />
            <InputBox
              label={"Lease End"}
              type={"date"}
              placeholder={"Lease End"}
              name="leaseEnd"
              required
              onChange={handleChange}
              value={formData.leaseEnd}
              disabled={disabled}
            />
            <InputBox
              label={"Number of Occupants"}
              type={"number"}
              placeholder={"No. of Occupants"}
              name="occupants"
              required
              onChange={handleChange}
              value={formData.occupants}
              disabled={disabled}
            />
            <SelectInput
              label="Type of Lease"
              name="leaseType"
              options={leaseTypeOptions}
              onChange={handleSelectLeaseType}
              value={leaseTypeOptions.find(
                (option) =>
                  option.value?.toLowerCase() ===
                  formData.leaseType?.toLowerCase()
              )}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-4 mt-4 ">
          {addMode && (
            <button
              className="bg-blue-900 text-white px-4 py-2 rounded-lg hover:bg-blue-800 active:bg-blue-900"
              type="submit"
            >
              {isProcesssing ? "Processing..." : "Add Tenant"}
            </button>
          )}

          {editMode && (
            <button
              className="bg-blue-900 text-white px-4 py-2 rounded-lg hover:bg-blue-800 active:bg-blue-900"
              type="button"
              onClick={handleSaveEdits}
            >
              {isProcesssing ? "Processing..." : "Save Changes"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default AddTenantForm;
