import React, { useEffect } from "react";
import NoPhoto from "../../assets/blankphoto.webp";
import {
  setDocData,
  storage,
  getDocDataSnap,
} from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";
import InputBox from "../Components/InputBox";
import { useParams } from "react-router-dom";

export default function OccupantFormData({ occupantId }) {
  const { id } = useParams();

  const [formData, setFormData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [occupant, setOccupant] = React.useState({});
  const [editMode, setEditMode] = React.useState(false);
  const [allOccupants, setAllOccupants] = React.useState([]);

  useEffect(() => {
    const fetchOccupant = async () => {
      getDocDataSnap("tenants", id, (tenant) => {
        const occupant = tenant.occupantsName.find(
          (item) => item.id === occupantId
        );
        setAllOccupants(tenant.occupantsName);
        setOccupant(occupant);
        setFormData(occupant);
        setIsLoading(false);
      });
    };
    fetchOccupant();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    await setDocData("tenants", id, {
      occupantsName: allOccupants.map((item) =>
        item.id === occupantId ? formData : item
      ),
    });
    toast.success("Occupant updated successfully");
    setIsProcessing(false);
    setEditMode(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleEditMode = () => {
    setEditMode(!editMode);

    if (editMode) {
      setFormData(occupant);
      toast.error("Changes discarded");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <button type="button" className="blue-button" onClick={handleEditMode}>
          {editMode ? "Cancel" : "Edit Information"}
        </button>
      </div>
      <div className="flex items-start justify-normal flex-wrap gap-4">
        <div className="w-full lg:w-[500px] space-y-4">
          <InputBox
            label={"First Name"}
            name="firstName"
            value={formData.firstName}
            placeholder={"Juan"}
            required
            onChange={handleChange}
            disabled={!editMode}
          />

          <InputBox
            label={"Middle Name"}
            value={formData.middleName}
            placeholder={"Santos"}
            required
            onChange={handleChange}
            disabled={!editMode}
            name="middleName"
          />

          <InputBox
            label={"Last Name"}
            value={formData.lastName}
            placeholder={"Dela Cruz"}
            required
            onChange={handleChange}
            disabled={!editMode}
            name="lastName"
          />

          <InputBox
            label={"Address"}
            value={formData.address}
            placeholder={"Address"}
            required
            onChange={handleChange}
            disabled={!editMode}
            name="address"
          />

          <InputBox
            label={"Birthday"}
            value={formData.birthday}
            placeholder={"Birthday"}
            type="date"
            required
            onChange={handleChange}
            disabled={!editMode}
            name="birthday"
          />
        </div>

        <div className="w-full lg:w-[500px] space-y-4">
          <InputBox
            label={"Relationship to Lessee"}
            value={formData.relationship}
            placeholder={"Wife"}
            required
            onChange={handleChange}
            disabled={!editMode}
            name="relationship"
          />
        </div>
      </div>
      {editMode && (
        <div className="mt-4">
          <button
            type="submit"
            className="blue-button w-full lg:w-fit"
            disabled={isProcessing}
          >
            {isProcessing ? "Processing..." : "Save"}
          </button>
        </div>
      )}
    </form>
  );
}
