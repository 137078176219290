import React from "react";
import UserScreenWindow from "./UserScreenWindow";

function SOS() {
  return (
    <UserScreenWindow label="Emergency Information">
      <div className="bg-white rounded-xl p-2 mt-4">
        <div className="text-center text-sm font-bold text-blue-900 mt-8">
          Emergency Hotlines
        </div>
        <div className="font-bold text-center text-green-800">
          Tap the numbers below to call
        </div>

        <div className="text-sm font-bold text-gray-600 mt-8">PNP Nasugbu</div>
        <div className="flex flex-col">
          <a href="tel:09245001199">0924 500 1199</a>
          <a href="tel:09985985697">0998 598 5697</a>
        </div>

        <div className="text-sm font-bold text-gray-600 mt-8">
          Municipality of Nasugbu
        </div>
        <a href="tel:09175234496">0917 523 4496</a>

        <div className="text-sm font-bold text-gray-600 mt-8">
          Bureau of Fire Protection - Nasugbu
        </div>
        <div className="flex flex-col">
          <a href="tel:0434160125">043 416 0125</a>
          <a href="tel:09954465073">0995 446 5073</a>
        </div>

        <div className="text-sm font-bold text-gray-600 mt-8">
          Coast Guard Substation - Nasugbu
        </div>
        <a href="tel:09687058583">0968 705 8583</a>

        <div className="text-sm font-bold text-gray-600 mt-8">
          Batelec Hotlines
        </div>
        <div className="flex flex-col">
          <a href="tel:09272456464">0927 245 6464</a>
          <a href="tel:09476364899">0947 636 4899</a>
          <a href="tel:09176397185">Trunkline - 0917 639 7185</a>
        </div>
      </div>

      <div className="text-lg mt-8 text-center">
        <div className="font-bold text-red-800">
          For Emergency Assistance Please Call
        </div>
        <div className="text-center">
          <div>Security Office</div>
          <div className="bg-red-700 text-white p-4 rounded-lg text-center">
            <a href="tel:+639123456789">+639123456789</a>
          </div>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default SOS;
