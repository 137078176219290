import React, { useEffect, useState } from "react";
import Modal from "../Components/Modal";
import NewInventoryForm from "./NewInventoryForm";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import ItemCard from "./ItemCard";
import PageModal from "../Components/PageModal";
import ItemDetailsPage from "./ItemDetailsPage";

export default function Inventoryv2() {
  const [addMode, setAddMode] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [seletedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchItems = async () => {
      await getAllDocsSnap("inventory", (data) => {
        setItems(data || []);
        setIsLoading(false);
      });
    };
    fetchItems();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleSelect = (selected) => {
    setSelectedItem(selected);
    setViewMode(!viewMode);
  };

  return (
    <div className="text-gray-600 text-xs text-left">
      <div className="header">Inventory Management</div>

      <div>
        <button className="blue-button" onClick={handleAddMode}>
          Add New Inventory Item
        </button>
      </div>

      {items.map((item, index) => (
        <div key={index} className="mt-2" onClick={() => handleSelect(item)}>
          <ItemCard data={item} />
        </div>
      ))}

      {addMode && (
        <Modal>
          <NewInventoryForm setAddMode={setAddMode} />
        </Modal>
      )}

      {viewMode && (
        <PageModal>
          <ItemDetailsPage data={seletedItem} setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}
