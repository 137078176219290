import React from "react";

function Label({ label, children, ...otherProps }) {
  return (
    <>
      <div className="flex flex-col gap-1 text-left">
        <label className="text-blue-900 text-xs uppercase font-bold">
          {label}
        </label>
        <div {...otherProps}>{children}</div>
      </div>
    </>
  );
}

export default Label;
