import React from "react";

export default function GatePassCard({ data }) {
  return (
    <div className="bg-white flex flex-col lg:flex-row items-start lg:items-center rounded-md shadow-sm p-2 cursor-pointer hover:bg-gray-200">
      <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
        <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
          Lot Number
        </div>
        <div className="font-bold px-1 text-blue-900">{data.identifier}</div>
      </div>

      <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
        <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
          Delivery Date
        </div>
        <div className="font-bold px-1 text-blue-900">{data.deliveryDate}</div>
      </div>

      <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
        <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
          Provider
        </div>
        <div className="font-bold px-1 text-blue-900 capitalize">
          {data.deliveryCompany}
        </div>
      </div>

      <div className={`capitalize ${data.status.toLowerCase()}`}>
        {data.status}
      </div>
    </div>
  );
}
