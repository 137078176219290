import React from "react";

function RequestsCard({ request }) {
  //firebasedate converter
  const handleDate = (date) => {
    return new Date(date.seconds * 1000).toLocaleDateString();
  };

  return (
    <div className="bg-white p-2 rounded-xl text-xs text-gray-600 hover:bg-blue-200 cursor-pointer relative">
      <div className="absolute top-1 right-1">
        <div className={request.status.toLowerCase()}>{request.status}</div>
      </div>
      <div className="font-semibold uppercase">
        Request Type: {request.workPermitType}
      </div>
      <div>Duration in days: {request.duration}</div>
      <div>Date Requested: {request.createdAt}</div>
      <div>House: {request.identifier}</div>
    </div>
  );
}

export default RequestsCard;
