import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function MaintenanceRequestConfirmation() {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <UserScreenWindow label={"Maintenance Request Confirmation"}>
      <div className="text-center font-bold text-blue-900 mt-8">
        Maintenance Request Confirmation
      </div>
      <div className="mt-8 flex flex-col items-center justify-center p-4 text-sm text-center">
        <div>Your Request is Confirmed</div>
        <div className="text-gray-600 font-bold">Confirmation Number:</div>
        <div>{id}</div>
      </div>

      <div>
        <button
          className="w-full bg-blue-900 text-white rounded-lg p-2"
          onClick={() => navigate("/dashboard")}
        >
          Back to Home
        </button>
      </div>
    </UserScreenWindow>
  );
}

export default MaintenanceRequestConfirmation;
