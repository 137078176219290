import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { queryAllDocsByFieldSnap } from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import GroupChatCard from "./GroupChatCard";
import Modal from "../Dashboard/Components/Modal";
import CreateGroupChatForm from "./CreateGroupChatForm";
import { useSelector } from "react-redux";

export default function GroupChatHome() {
  const currentUser = useSelector((state) => state.userObject);
  const [groupChats, setGroupChats] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [createGroup, setCreateGroup] = React.useState(false);

  useEffect(() => {
    const fetchGroupChats = async () => {
      await queryAllDocsByFieldSnap(
        "groupChats",
        "members",
        "array-contains",
        currentUser.uid,
        (data) => {
          //arrange group chats by name
          data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          setGroupChats(data);
          setIsLoading(false);
        }
      );
    };

    fetchGroupChats();
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label={"Group Chats"}>
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const createGroupChat = () => {
    setCreateGroup(!createGroup);
  };

  return (
    <div className="relative">
      <UserScreenWindow label="Group Chats">
        <div>
          <button
            className="blue-button text-xs fixed top-2 right-10 z-20"
            onClick={createGroupChat}
          >
            Create a group chat
          </button>
        </div>
        <div className="space-y-2 mt-4">
          {groupChats.map((groupChat, index) => (
            <GroupChatCard key={index} groupChat={groupChat} />
          ))}
        </div>
        {createGroup && (
          <Modal>
            <CreateGroupChatForm createGroupChat={createGroupChat} />
          </Modal>
        )}
      </UserScreenWindow>
    </div>
  );
}
