import React from "react";

export default function TransactionCard({ data }) {
  return (
    <div className="w-full">
      <div>No. of Items: {data.quantity}</div>
      <div>{data.cost} per item</div>
      <div>
        {data.date} - {data.user}
      </div>
    </div>
  );
}
