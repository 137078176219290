import React, { useEffect, useState } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import {
  getDocData,
  getDocDataSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function ConcernDetails() {
  const { id } = useParams();
  const [concern, setConcern] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [addMode, setAddMode] = useState(false);

  const [newComment, setNewComment] = useState("");

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchConcern = async () => {
      const concernData = await getDocDataSnap("concerns", id, (data) => {
        setConcern(data);
        setIsLoading(false);
      });
    };
    fetchConcern();
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label="Concern Details">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const lastEighthChars = id.slice(-8);

  const comments = concern.comments ? concern.comments : [];

  //check if concern.image is video or image
  const isVideo = concern.image ? concern.image.includes("mp4") : false;

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleNewComment = (e) => {
    setNewComment(e.target.value);
  };

  const handleSubmitComment = async () => {
    const docObject = {
      message: newComment,
      createdBy: currentUser.displayName,
      createdAt: new Date().toLocaleString(),
    };
    await setDocData("concerns", id, {
      comments: [...comments, docObject],
    });
    toast.success("Comment added");
    setAddMode(false);
  };

  return (
    <UserScreenWindow label="Concern Details">
      <div className="header">Details of Concern</div>
      <div className="space-y-4 text-sm text-gray-600">
        <div className="flex items-center justify-between">
          <div>Reference Number</div>
          <div>{lastEighthChars}</div>
        </div>
        <div className="flex items-center justify-between gap-8">
          <div>Type of Concern</div>
          <div> {concern.concern}</div>
        </div>
        <div className="flex items-center justify-between gap-8">
          <div>Date Filed:</div>
          <div>{concern.createdAt}</div>
        </div>
        <div className="flex items-center justify-between gap-8">
          <div>Status</div>
          <div className={concern.status.toLowerCase()}>{concern.status}</div>
        </div>
        <div className="">
          <div>Description</div>
          <div>{concern.description}</div>
        </div>
        <div className="header">Image or Video</div>
        <div className="">
          {concern.image ? (
            <img
              className="w-60 object-contain aspect-square"
              src={concern.image}
              alt=""
            />
          ) : null}

          {concern.video ? (
            <div className="p-2 rounded-lg bg-green-400 w-fit mt-2">
              <a href={concern.video} target="_blank">
                View Video
              </a>
            </div>
          ) : (
            <div className="mt-2 bg-white w-fit p-2 ">No Video Uploaded</div>
          )}
        </div>

        <div className="">
          <div className="mb-8 header">Comments</div>
          <button className="blue-button" onClick={handleAddMode}>
            Add Comment
          </button>
          {addMode && (
            <>
              <textarea
                name="newComment"
                rows={4}
                className="w-full p-2 rounded-lg border mt-2"
                placeholder="Add new comment here"
                id=""
                onChange={handleNewComment}
              ></textarea>
              <div className="text-right">
                <button className="blue-button" onClick={handleSubmitComment}>
                  Submit
                </button>
              </div>
            </>
          )}
          {comments.map((comment, index) => (
            <div key={index} className="">
              <div className="text-xs mt-2 bg-white p-2 rounded-md shadow-sm">
                <div>{comment.createdBy}</div>
                <div>{comment.message}</div>
                <div>{comment.createdAt}</div>
              </div>
            </div>
          ))}
        </div>

        <div>
          <div className="header mt-4">Resolutions</div>
          <div className="flex items-center justify-between mt-2">
            <div>Assigned to</div>
            <div>{concern?.dispatchee || "Property Management"}</div>
          </div>

          <div>Last Updated by: {concern.updatedName}</div>
          <div>Last Updated on: {concern.updatedAt}</div>
          <div className="mt-2 font-bold">Resolution Report</div>
          <div>
            {concern.resolution ? concern.resolution : "Pending Resolution"}
          </div>
        </div>
      </div>
    </UserScreenWindow>
  );
}
