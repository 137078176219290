import React from "react";
import { useSelector } from "react-redux";

export default function ProfileWeb() {
  const currentUser = useSelector((state) => state.userObject);
  return (
    <div className="text-gray-600 text-xs text-left">
      <div className="header">Your Profile</div>

      <div className="w-96 flex items-center justify-between">
        <div className="text-left">Display Name:</div>
        <div className="text-right">{currentUser.displayName}</div>
      </div>

      <div className="w-96 flex items-center justify-between">
        <div className="text-left">Email Address:</div>
        <div className="text-right">{currentUser.email}</div>
      </div>

      <div className="w-96 flex items-center justify-between">
        <div className="text-left">Access Rights:</div>
        <div>
          {currentUser.roles.map((role, index) => (
            <div className="text-right" key={index}>
              {role}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
