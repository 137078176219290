import React from "react";
import blankPhoto from "../assets/blankphoto.webp";

function ProfilePhotoWithDetails({ image, name, contactNumber }) {
  return (
    <div className="flex flex-col items-center justify-center text-xs text-gray-600">
      <img
        src={image || blankPhoto}
        className="w-16 h-16 rounded-full object-cover"
        alt=""
      />
      <div className="font-bold">{name}</div>
      <a href={`tel:${contactNumber}`}>{contactNumber}</a>
    </div>
  );
}

export default ProfilePhotoWithDetails;
