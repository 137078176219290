import React from "react";
import { FaCar } from "react-icons/fa";
import { GoPersonFill } from "react-icons/go";

export default function ResidentVehiclesCard({ vehicle }) {
  return (
    <div className="bg-white rounded-md shadow-sm p-2 hover:bg-gray-200 cursor-pointer flex items-start gap-4 flex-wrap flex-col lg:flex-row max-w-[1440px] relative">
      <div className="flex items-center gap-2 w-60">
        <FaCar className="text-2xl text-gray-500" />
        <div>{vehicle.plateNumber}</div>
        <div>{vehicle.model}</div>
      </div>

      <div className="flex items-center gap-2 w-60">
        <GoPersonFill className="text-xl text-gray-500" />
        {vehicle.nameRegistered}
      </div>

      <div className="absolute top-2 right-2">
        <div
          className={
            vehicle.status === "documents pending"
              ? "pending"
              : vehicle.status === "approved"
              ? "activeStatus"
              : vehicle.status === "rejected"
              ? "rejected"
              : vehicle.status === "inactive"
              ? "inactive"
              : ""
          }
        >
          {vehicle.status}
        </div>
      </div>
    </div>
  );
}
