import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { getAllDocs } from "../../utils/firebase/Firebase.utils";
import SupplierContactCard from "./SupplierContactCard";

export default function Suppliers() {
  const [suppliers, setSuppliers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchSuppliers = async () => {
      const suppliers = await getAllDocs("suppliers");
      setSuppliers(suppliers);
      setIsLoading(false);
    };
    fetchSuppliers();
  }, []);

  return (
    <UserScreenWindow label="Suppliers">
      <div className="min-h-screen">
        <div className="header uppercase text-sm">Suppliers</div>

        <div>
          <div className="mt-8 flex items-start justify-center flex-wrap">
            {suppliers.map((supplier, index) => (
              <div key={index}>
                <SupplierContactCard supplier={supplier} />
              </div>
            ))}
          </div>
          <div className="mt-16">
            <div className="header">DISCLAIMER</div>
            <p className="text-justify p-2 text-xs text-gray-600 mt-auto">
              <span className="text-blue-900 font-bold">
                TALI HOME OWNERS GROUP (THOG)
              </span>{" "}
              through this app provides a platform that connects users with
              suppliers. However, THOG is not a party to any transaction or
              agreement between users and suppliers. As such, we do not endorse,
              guarantee, or assume any responsibility for the products,
              services, or actions of any supplier. Users are advised to
              exercise due diligence and caution in their interactions with
              suppliers. THOG shall not be liable for any losses or damages
              resulting from transactions made through the platform. By using
              our platform, you acknowledge that transactions are conducted at
              your own risk.
            </p>
          </div>
        </div>
      </div>
    </UserScreenWindow>
  );
}
