import React from "react";

export default function FileUploadAndView({
  activeUpload,
  progress,
  files,
  handleUpload,
  doc,
}) {
  return (
    <div className="text-xs mt-4 bg-green-100 rounded-lg p-2 border">
      <div className="flex flex-col items-start">
        <label className="font-bold text-xs text-gray-600">Upload {doc}</label>
        <div className="flex items-center justify-between gap-4 w-full">
          <div>
            <input type="file" onChange={(e) => handleUpload(e, doc)} />
            {activeUpload === doc && (
              <progress value={progress} max="100"></progress>
            )}
          </div>
          <div className=" ">
            {files[doc] ? (
              <a
                href={files[doc]}
                target="_blank"
                className="bg-green-400 px-2 py-2 rounded-lg"
              >
                View File
              </a>
            ) : (
              <div className="bg-gray-200 px-2 py-2 rounded-lg">
                No File Uploaded
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
