import React from "react";

function InputBox({
  label,
  type,
  placeholder,
  value,
  onChange,
  ...otherProps
}) {
  return (
    <>
      <div className="flex flex-col gap-1 text-left">
        <label className="text-blue-900 text-xs uppercase font-bold">
          {label}
        </label>
        <input
          className="border border-gray-300 px-2 py-1 rounded-lg"
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...otherProps}
        />
      </div>
    </>
  );
}

export default InputBox;
