import React from "react";
import {
  getAllDocsSnap,
  setDocData,
  getEmailsOfAllFirebaseUsers,
} from "../../utils/firebase/Firebase.utils";
import { useState, useEffect } from "react";
import Select from "react-select";
import InputBox from "../Dashboard/Components/InputBox";
import toast from "react-hot-toast";
import Modal from "../Dashboard/Components/Modal";
import UserDetails from "./UserDetails";
import { useSelector } from "react-redux";

function UserAccess() {
  const currentUser = useSelector((state) => state.userObject);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [emailInProgress, setEmailInProgress] = useState(false);
  const [emailingUser, setEmailingUser] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const getUsers = async () => {
      const data = getAllDocsSnap("users", (users) => {
        users.sort((a, b) => {
          if (a.displayName < b.displayName) {
            return -1;
          }
          if (a.displayName > b.displayName) {
            return 1;
          }
          return 0;
        });

        setUsers(users);

        const useremails = users.map((user) => {
          return user.email;
        });

        useremails.sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        });

        setFilteredUsers(users);
        setIsLoading(false);
      });
      //arrange users by name
    };
    getUsers();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const options = [
    { value: "super-admin", label: "Super Admin" },
    { value: "user", label: "User" },
    { value: "admin", label: "Admin" },
    { value: "approver", label: "Approver" },
    { value: "bod", label: "BOD" },
    { value: "security", label: "Security" },
    { value: "finance", label: "Finance" },
    { value: "maker", label: "Maker" },
    { value: "restricted", label: "Restricted" },
    { value: "reviewer", label: "Reviewer" },
  ];

  const selectUserForEditing = (id) => {
    setSelectedUserForEdit(id);

    if (selectedUserForEdit === id) {
      setSelectedUserForEdit(null);
    }
  };

  const handleSelect = (userid, selectedOption) => {
    let roles = [];
    selectedOption.map((option) => {
      roles.push(option.value);
    });
    setDocData("users", userid, { roles: roles });
  };

  const handleSelectDepartment = (userid, selectedOption) => {
    setDocData("users", userid, { department: selectedOption.value });
  };

  const departmentOptions = [
    { value: "admin", label: "Admin" },
    { value: "security", label: "Security" },
    { value: "accounting", label: "Accounting" },
  ];

  const handleSearch = (e) => {
    const search = e.target.value;
    const filtered = users.filter((user) => {
      return user.displayName?.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredUsers(filtered);
  };

  const handleEmailConfimration = async (email, name) => {
    setEmailInProgress(true);
    setEmailingUser(email);
    const fetchReq = async () => {
      const res = await fetch(
        "https://talieleghoemailsender.vercel.app/api/subscribe",
        {
          method: "POST",
          body: JSON.stringify({
            name,
            email,
          }),
        }
      );
    };
    fetchReq();
    await setDocData("users", email, { emailSent: true });
    toast.success("Email sent");
    setEmailInProgress(false);
    setEmailingUser(null);
  };

  const handleViewMode = (user) => {
    setSelectedUser(user);
    setViewMode(true);
  };

  if (currentUser.roles?.includes("super-admin")) {
    return (
      <div className="p-4 text-xs">
        <div>
          <div className="mb-4">
            <InputBox
              label="Search User"
              onChange={handleSearch}
              type={"search"}
            />
          </div>
          <table className="text-left">
            <tr className="[&>*]:p-2 [&>*]:bg-gray-200">
              <th>Full Name</th>
              <th>Email</th>
              <th className="w-72">Department</th>
              <th className="w-72">Roles</th>
              <th className="w-72">Assign Roles</th>
            </tr>
            {filteredUsers.map((user) => (
              <tr
                className="[&>*]:p-2 [&>*]:text-xs border-b border-gray-200"
                key={user.id}
              >
                <td>{user.displayName}</td>
                <td>{user.email}</td>
                <td>
                  {selectedUserForEdit === user.id ? (
                    <Select
                      onChange={(selectedOption) =>
                        handleSelectDepartment(user.id, selectedOption)
                      }
                      options={departmentOptions}
                      name="department"
                      value={departmentOptions.filter((item) => {
                        return user.department === item.value;
                      })}
                    />
                  ) : (
                    user.department
                  )}
                </td>
                <td>
                  {selectedUserForEdit === user.id ? (
                    <Select
                      onChange={(selectedOption) =>
                        handleSelect(user.id, selectedOption)
                      }
                      options={options}
                      isMulti
                      name="roles"
                      value={options.filter((item) => {
                        return user.roles?.includes(item.value);
                      })}
                    />
                  ) : (
                    <div>
                      {user.roles?.map((role) => (
                        <span
                          className="bg-gray-200 p-2 rounded-md mr-2"
                          key={role}
                        >
                          {role}
                        </span>
                      ))}
                    </div>
                  )}
                </td>
                <td className="flex items-center gap-2 text-xs">
                  <button
                    className="bg-blue-500 text-white px-2 py-1 rounded-md"
                    onClick={() => handleViewMode(user)}
                  >
                    See Details
                  </button>
                  <button
                    className="bg-blue-500 text-white px-2  py-1 rounded-md"
                    onClick={() => selectUserForEditing(user.id)}
                  >
                    {selectedUserForEdit === user.id ? "Update" : "Edit"}
                  </button>
                  <button
                    className={`px-2 py-1 rounded-md  ${
                      emailingUser === user.email
                        ? "bg-gray-200 text-gray-600 cursor-not-allowed"
                        : "text-white bg-blue-500"
                    }`}
                    onClick={() =>
                      handleEmailConfimration(user.email, user.displayName)
                    }
                  >
                    {emailInProgress && emailingUser === user.email
                      ? "Sending..."
                      : "Send Email"}
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>

        {viewMode && (
          <Modal>
            <div
              className="underline cursor-pointer text-right"
              onClick={() => setViewMode(false)}
            >
              Close
            </div>
            <UserDetails id={selectedUser.id} setViewMode={setViewMode} />
          </Modal>
        )}
      </div>
    );
  } else {
    return <div className="header mx-auto">Access Denied - Only Super Admins Can Access</div>;
  }
}

export default UserAccess;
