import UserScreenWindow from "./UserScreenWindow";
import Select from "react-select";
import { useEffect, useState } from "react";
import {
  getDocDataSnap,
  storage,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import FileUploadAndView from "./FileUploadAndView";

function WorkPermitUser() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  const id = searchParams.get("continue");

  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [identifiers, setIdentifiers] = useState([]);
  const [files, setFiles] = useState({});
  const [assignedId, setAssignedId] = useState(
    id ? id : v4().slice(0, 8).toUpperCase()
  );
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState("");
  const [activeUpload, setActiveUpload] = useState(false);
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getUser = async () => {
      const userSnap = getDocDataSnap("users", currentUser.uid, (data) => {
        setIdentifiers(data.linkedIdentifiers);
      });
    };
    getUser();
  }, []);

  useEffect(() => {
    const fetchRequest = async () => {
      const data = await getDocDataSnap("workPermits", assignedId, (data) => {
        setFormData(data);
        setFiles(data.files || {});
        setIsLoading(false);
      });
    };

    if (id) {
      fetchRequest();
    } else {
      setIsLoading(false);
    }
  }, []);

  console.log(id);
  console.log(assignedId);

  if (isLoading) {
    return (
      <UserScreenWindow label="Work Permit Request">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const options = [
    { value: "minor work", label: "Minor Work and Repairs " },
    { value: "major work", label: "Major Renovation" },
    { value: "construction", label: "House or Fence Construction" },
  ];

  const identifierOptions = identifiers.map((id) => ({ value: id, label: id }));

  const handleSelect = (e, name) => {
    setFormData({ ...formData, [name]: e.value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSettingFileList = (e, fileName) => {
    setFiles([...files, { name: fileName, file: e.target.files[0] }]);
  };

  const handleUpload = (e, fileName) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `workPermits/${assignedId}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setActiveUpload(fileName);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          setFiles({
            ...files,
            [fileName]: downloadURL,
          });
          setActiveUpload(false);
        });
      }
    );
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    const docObject = {
      ...formData,
      status: "Pending",
      name: currentUser.displayName,
      files: files,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.email,
      user: currentUser.uid,
    };

    await setDocData("workPermits", assignedId, docObject);
    navigate("/work-permit-confirmation/" + assignedId);
  };

  console.log(files);

  return (
    <UserScreenWindow label="Work Permit Request">
      <div>
        <div className="text-center font-bold mt-4 text-blue-900">
          Work Permits
        </div>
        <div>
          <label className="font-bold text-gray-600 text-xs">
            Choose Type of Work Permit Application
          </label>
          <Select
            options={options}
            className="text-xs"
            onChange={(e) => handleSelect(e, "workPermitType")}
            value={options.find(
              (option) => option.value === formData.workPermitType
            )}
          />
        </div>

        {formData.workPermitType === "major work" && (
          <div className="p-2 text-xs text-gray-600">
            Major Renovation: Additional new structures, major repairs, changes
            in current structure of the house.
          </div>
        )}

        <div>
          <label className="font-bold text-xs text-gray-600">
            Select House Number
          </label>
          <Select
            options={identifierOptions}
            className="text-xs"
            onChange={(e) => handleSelect(e, "identifier")}
            value={identifierOptions.find(
              (option) => option.value === formData.identifier
            )}
          />
        </div>

        <div className="flex flex-col items-start mt-2">
          <label htmlFor="" className="font-bold text-xs text-gray-600">
            Duration of Activities
          </label>
          <input
            type="number"
            className="w-full rounded-lg border border-gray-100 p-2 text-sm"
            placeholder="Indicate number of Days"
            onChange={handleChange}
            name="duration"
            value={formData.duration}
          />
        </div>

        <div>
          <label className="font-bold text-xs text-gray-600">
            Provide a description of work to be done
          </label>
          <textarea
            className="w-full rounded-lg border border-gray-100 p-2 text-sm"
            rows={5}
            onChange={handleChange}
            placeholder="Description"
            name="description"
            value={formData.description}
          />
        </div>

        <div>
          <label className="font-bold text-xs text-gray-600">
            Provide names of workers
          </label>
          <textarea
            className="w-full rounded-lg border border-gray-100 p-2 text-sm"
            rows={5}
            onChange={handleChange}
            placeholder="Workers List"
            name="workers"
            value={formData.workers}
          />
        </div>

        <div className="flex flex-col items-start mt-2">
          <label htmlFor="" className="font-bold text-xs text-gray-600">
            Vehicle Information of Workers/Contractor
          </label>
          <input
            type="text"
            name="vehicle"
            className="w-full rounded-lg border border-gray-100 p-2 text-sm"
            placeholder="Indicate vehicle type and plate number"
            onChange={handleChange}
            value={formData.vehicle}
          />
        </div>

        {formData.workPermitType === "major work" && (
          <div className="mt-4">
            <FileUploadAndView
              doc="Renovation Plan"
              files={files}
              handleUpload={handleUpload}
              progress={progress}
              activeUpload={activeUpload}
            />

            <FileUploadAndView
              doc="Renovation Permit"
              files={files}
              handleUpload={handleUpload}
              progress={progress}
              activeUpload={activeUpload}
            />
          </div>
        )}

        {formData.workPermitType === "construction" && (
          <div className="mt-4">
            <FileUploadAndView
              doc="Construction Plans"
              files={files}
              handleUpload={handleUpload}
              progress={progress}
              activeUpload={activeUpload}
            />

            <FileUploadAndView
              doc="Construction Permit"
              files={files}
              handleUpload={handleUpload}
              progress={progress}
              activeUpload={activeUpload}
            />

            <FileUploadAndView
              doc="Building Permit"
              files={files}
              handleUpload={handleUpload}
              progress={progress}
              activeUpload={activeUpload}
            />
          </div>
        )}

        {formData.duration > 5 && formData.workPermitType === "minor work" && (
          <div className="text-gray-600 text-xs p-2 ">
            Reminder: Minor works exceeding 5 days is required to pay the
            following bonds:
            <ul className="font-semibold list-disc ml-4">
              <li>Repair Bond: Php 15,000</li>
              <li>Sanitation Bond: Php 10,000</li>
            </ul>
          </div>
        )}

        {formData.workPermitType === "major work" && (
          <div className="text-gray-600 text-xs p-2 ">
            Reminder: Major works bond requirments:
            <ul className="font-semibold list-disc ml-4">
              <li>Renovation Bond: Php 75,000</li>
              <li>Sanitation Bond: Php 10,000</li>
            </ul>
          </div>
        )}

        {formData.workPermitType === "construction" && (
          <div className="text-gray-600 text-xs p-2 ">
            Reminder: Construction bond requirments:
            <ul className="font-semibold list-disc ml-4">
              <li>Construction Bond: Php 100,000</li>
              <li>Sanitation Bond: Php 10,000</li>
              <li>Electrical Distribution Tapping Fee: Php 20,000</li>
              <li>Water System Connection Fee: Php 100,000</li>
            </ul>
          </div>
        )}

        {formData.duration > 5 && formData.workPermitType === "minor work" && (
          <FileUploadAndView
            doc="Bond Payment"
            files={files}
            handleUpload={handleUpload}
            progress={progress}
            activeUpload={activeUpload}
          />
        )}

        {formData.workPermitType !== "minor work" && (
          <FileUploadAndView
            doc="Bond Payment"
            files={files}
            handleUpload={handleUpload}
            progress={progress}
            activeUpload={activeUpload}
          />
        )}

        <div className="text-center">
          <button
            className="bg-blue-900 text-white p-2 rounded-lg mt-4 w-full"
            onClick={handleSubmit}
          >
            {isProcessing ? "Processing..." : "Submit"}
          </button>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default WorkPermitUser;
