import React from "react";
import TenantDocuments from "./TenantDocuments";
import { useParams } from "react-router-dom";

export default function DocumentsTenants({ tenant }) {
  const { id } = useParams();

  return (
    <div className="text-xs">
      <TenantDocuments tenantId={id} />

      <div className="mt-4">
        <div className="header">Violations and Penalties</div>

        <div>No Violations and Penalties</div>
      </div>
    </div>
  );
}
