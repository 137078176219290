import React from "react";
import { useSearchParams } from "react-router-dom";

import GatePass from "./GatePass";
import GuestPass from "./GuestPass";
import IncidentReports from "./IncidentReports";

export default function SecurityNewPage() {
  const links = ["Gate Pass", "Guest Pass", "Incident Reports"];
  const [activeLink, setActiveLink] = React.useState("Gate Pass");
  const [searchParams] = useSearchParams();

  return (
    <div className="text-xs text-left mt-8">
      <div className="header">Security </div>
      <div className="links-container">
        {links.map((link, index) => (
          <div
            key={index}
            className={
              activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
            }
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      {activeLink === "Gate Pass" && <GatePass />}
      {activeLink === "Guest Pass" && <GuestPass />}
      {activeLink === "Incident Reports" && <IncidentReports />}

      <div></div>
    </div>
  );
}
